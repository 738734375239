import { useEffect, useState } from 'react';
import { getLastTeacherInput } from '../../../api/insight';
import {
  getUserPopUpStatus,
  recordPopUpClose,
  recordPopUpOpen,
} from '../../../api/popUpStatus';
import { TeacherInputModal } from '../../../components/modals/teacherInputModal/teacherInputModal';
import { getTeacherMessage } from '../../../api/ai';
import { returnUserByUID } from '../../../firebase/configuration';
import { UserData } from '../../../types/user';
import { getFirstNameFromFullName } from '../../../util/helper';
import { trackTeacherInputReportNotifier } from './analytics';
import { createTeacherMessageForUser } from '../../../api/users';

export const TeacherInputReportNotifier = (props: { uid: string }) => {
  const { uid } = props;
  const [openModal, setOpenModal] = useState(false);
  const [teacherInput, setTeacherInput] = useState();
  const [teacherId, setTeacherId] = useState();

  async function openModalHandler(metaData: any) {
    setOpenModal(true);
    const res = await recordPopUpOpen(uid, 'teacher-input-message', metaData);
    console.log(res);
  }

  async function onCloseModal() {
    await recordPopUpClose(uid, 'teacher-input-message');
    trackTeacherInputReportNotifier(
      'Teacher input report notifier modal closed',
      {
        message: teacherInput,
      },
    );
    setOpenModal(false);
  }

  async function onLoad() {
    const last5Inputs = await getLastTeacherInput(uid, 5);

    function findOverlap(arr1: Array<string>, arr2: Array<string>) {
      const set2 = new Set(arr2);
      return arr1.filter((item) => set2.has(item));
    }

    const popUpStatusReport: PopUpStatus = await getUserPopUpStatus(
      uid,
      'teacher-input-message',
    );

    console.log(last5Inputs);
    // await openModalHandler();
    // If never opened
    if (
      (!popUpStatusReport?.lastOpened && last5Inputs.length >= 1) ||
      (!findOverlap(
        popUpStatusReport?.metaData?.sessions ?? [],
        last5Inputs?.map((i: any) => i.id),
      ).length &&
        last5Inputs.length >= 5)
    ) {
      const workOn = new Set<string>();
      const improved = new Set<string>();
      setTeacherId(last5Inputs[0].raterId);

      last5Inputs.forEach((input: any) => {
        input.workOn?.forEach((i: string) => {
          workOn.add(i);
        });
        input.improved?.forEach((i: string) => {
          improved.add(i);
        });
      });

      const user = (await returnUserByUID(uid)) as UserData;
      const { message } = await createTeacherMessageForUser(
        getFirstNameFromFullName(user.name),
        Array.from(workOn),
        Array.from(improved),
        last5Inputs[0].raterId,
        last5Inputs?.map((i: any) => i.id),
        uid,
      );

      console.log(message);

      setTeacherInput(message);
      trackTeacherInputReportNotifier(
        'Teacher input report notifier modal opened',
        {
          reportNumber: popUpStatusReport?.numberOpened,
          lastOpened: popUpStatusReport?.lastOpened,
          message: message,
          last5Inputs: last5Inputs,
        },
      );
      await openModalHandler({
        sessions: last5Inputs?.map((i: any) => i.id),
        message: message,
      });
    }
  }

  useEffect(() => {
    onLoad();
  }, []);
  return (
    <>
      {teacherInput && teacherId ? (
        <TeacherInputModal
          isOpen={openModal}
          onCloseModal={onCloseModal}
          message={teacherInput}
          teacherId={teacherId}
        />
      ) : null}
    </>
  );
};
