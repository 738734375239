import { MinusCircleIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { SimpleAddForm } from '../../../../components/forms/SimpleAddForm/SimpleAddForm.';
import {
  addTopicWeeklyTopicTitle,
  editTopicWeeklyPhraseList,
  editTopicWeeklyQuestionList,
  editTopicWeeklyTheme,
  editTopicWeeklyThemeLocation,
  editTopicWeeklyTopicSlide,
  editTopicWeeklyTopicTitle,
} from '../../../../firebase/subscription/topics/topics';
import moment from 'moment';
import VerticalMessageModal from '../../../../components/modals/verticalMessageModal/verticalMessageModal';
import TextInput from '../../../../components/forms/textInput';
import { Input } from '../../../../components/forms/input';
import SmallCardsRadio from '../../../../components/forms/smallCardsRadio/SmallCardsRadio';
import { compose } from 'redux';
import DropDownSearch from '../../../../components/forms/dropdownsearch';
import { capitalizeEveryFirstLetter } from '../../../../util/standardization';

export const Topics = (props: {
  topics: Array<any>;
  setLoading: Function;
  onUpdateFinished: Function;
  onUpdateStarted: Function;
}) => {
  const { topics, setLoading, onUpdateFinished, onUpdateStarted } = props;

  const [editTopicID, setEditTopicID] = useState<string | undefined>();
  const [editTopicIndex, setEditTopicIndex] = useState<number | undefined>();
  const [editPhrases, setEditPhrases] = useState<Array<string> | undefined>();
  const [editQuestions, setEditQuestions] = useState<
    Array<string> | undefined
  >();
  const [editTitle, setEditTitle] = useState<string | undefined>();
  const [editSlide, setEditSlide] = useState<string | undefined>();
  const [editTheme, setEditTheme] = useState<string | undefined>();
  const [editThemeLocation, setEditThemeLocation] = useState<
    string | undefined
  >();

  return (
    <>
      <VerticalMessageModal
        mainMessage={`Edit week ${(editTopicIndex ?? 0) + 1} topic`}
        subMessage=""
        theme="default"
        icon="pencil"
        buttonText="Save change"
        secondaryButtonText="Cancel"
        isOpen={editTopicIndex !== undefined}
        onCloseModal={() => {
          setEditTitle(undefined);
          setEditSlide(undefined);
          setEditTheme(undefined);
          setEditTopicID(undefined);
          setEditTopicIndex(undefined);
        }}
        onButtonClick={async () => {
          setLoading(true);
          const index = editTopicIndex;
          if (editTopicID && editTitle && index !== undefined)
            await editTopicWeeklyTopicTitle(editTopicID, editTitle, index); // Update title
          if (editTopicID && editSlide && index !== undefined)
            await editTopicWeeklyTopicSlide(editTopicID, editSlide, index); // Update main slide link
          if (editTopicID && editThemeLocation && index !== undefined)
            await editTopicWeeklyThemeLocation(
              editTopicID,
              editThemeLocation,
              index,
            ); // Update main slide link

          console.log(editThemeLocation);
          setEditTitle(undefined);
          setEditSlide(undefined);
          setEditTheme(undefined);
          setEditTopicID(undefined);
          setEditTopicIndex(undefined);
          setLoading(false);
        }}
      >
        <div className="text-left">
          <TextInput
            title={'Title'}
            onChange={(v: string) => {
              setEditTitle(v);
            }}
            value={editTitle}
          />
          <TextInput
            title={'Slide'}
            onChange={(v: string) => {
              setEditSlide(v);
            }}
            value={editSlide}
          />
          <SmallCardsRadio
            title="Theme"
            options={[
              {
                name: 'Social',
                tag: 'social',
                checked: editTheme === 'social',
              },
              {
                name: 'Career',
                tag: 'career',
                checked: editTheme === 'career',
              },
            ]}
            onChosen={async (theme: string) => {
              setLoading(true);
              console.log(editTopicID);
              console.log(editTopicIndex);
              if (editTopicID !== undefined && editTopicIndex !== undefined) {
                console.log(theme);
                await editTopicWeeklyTheme(editTopicID, theme, editTopicIndex);
              }
              setEditTheme(theme);
              setLoading(false);
            }}
          />

          <div className="block text-sm font-medium leading-6 text-gray-900">
            Phrases
          </div>
          {editPhrases?.map((value: string, index: number) => {
            return (
              <div className="flex">
                {`${index + 1}: ${value}`}
                <MinusCircleIcon
                  className="w-5 h-5 text-red-warning my-auto ml-2 cursor-pointer flex-shrink-0"
                  onClick={async () => {
                    console.log(value);
                    if (editTopicID && editTopicIndex && editPhrases) {
                      setLoading(true);
                      await editTopicWeeklyPhraseList(
                        editTopicID,
                        editPhrases.filter((p) => p !== value),
                        editTopicIndex,
                      );
                      setEditPhrases(editPhrases.filter((p) => p !== value));
                      setLoading(false);
                    }
                  }}
                />
              </div>
            );
          })}
          <SimpleAddForm
            label="Phrases"
            placeholder="type in a phrase"
            onAdd={async (value: string) => {
              if (editTopicID && editTopicIndex !== undefined && editPhrases) {
                setLoading(true);
                await editTopicWeeklyPhraseList(
                  editTopicID,
                  [...editPhrases, value.toLocaleLowerCase()],
                  editTopicIndex,
                );
                setEditPhrases([...editPhrases, value.toLocaleLowerCase()]);
                setLoading(false);
              }
            }}
          />
          <div className="block text-sm font-medium leading-6 text-gray-900 mt-4">
            Questions
          </div>
          {editQuestions?.map((value: string, index: number) => {
            return (
              <div className="flex">
                {`${index + 1}: ${value}`}
                <MinusCircleIcon
                  className="w-5 h-5 text-red-warning my-auto ml-2 cursor-pointer flex-shrink-0"
                  onClick={async () => {
                    if (
                      editTopicID &&
                      editTopicIndex !== undefined &&
                      editQuestions
                    ) {
                      setLoading(true);
                      await editTopicWeeklyQuestionList(
                        editTopicID,
                        editQuestions.filter((p) => p !== value),
                        editTopicIndex,
                      );
                      setEditQuestions(
                        editQuestions.filter((p) => p !== value),
                      );
                      setLoading(false);
                    }
                  }}
                />
              </div>
            );
          })}
          <SimpleAddForm
            label="Questions"
            placeholder="type in the question"
            onAdd={async (value: string) => {
              console.log(value);
              if (
                editTopicID &&
                editTopicIndex !== undefined &&
                editQuestions
              ) {
                setLoading(true);
                await editTopicWeeklyQuestionList(
                  editTopicID,
                  [...editQuestions, value],
                  editTopicIndex,
                );
                setEditQuestions([...editQuestions, value]);
                setLoading(false);
              }
            }}
          />
          <div className="w-64">
            <div className="text-sm mt-4 font-medium">Theme Location</div>
            <DropDownSearch
              options={[
                'Atlanta',
                'Austin',
                'Baltimore',
                'Boston',
                'Charlotte',
                'Chicago',
                'Columbus',
                'Dallas',
                'Denver',
                'Detroit',
                'Houston',
                'Jacksonville',
                'Las Vegas',
                'Los Angeles',
                'Memphis',
                'Miami',
                'Minneapolis',
                'New Orleans',
                'New York City',
                'Orange County',
                'Orlando',
                'Philadelphia',
                'Phoenix',
                'Pittsburgh',
                'Portland',
                'San Antonio',
                'San Diego',
                'San Francisco',
                'Seattle',
                'St Louis',
                'Tampa',
                'Washington D.C.',
                'Colorado Springs',
                'Salt Lake City',
                'San Jose',
                'Buffalo',
                'Sacramento',
                'Charleston',
                'Milwaukee',
                'Cincinnati',
                'Nashville',
                'Honolulu',
                'Tucson',
                'Indianapolis',
              ]}
              onSelect={(selected: string) => {
                const tag = selected.toLowerCase().split(' ').join('-');
                console.log(tag);
                setEditThemeLocation(tag);
              }}
              selected={
                editThemeLocation
                  ? capitalizeEveryFirstLetter(
                      editThemeLocation?.split('-')?.join(' '),
                    )
                  : undefined
              }
            />
          </div>
        </div>
      </VerticalMessageModal>
      <div className="lg:grid lg:grid-cols-6 lg:gap-x-12">
        <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
          {Object.keys(topics)?.map((t: any) => {
            const c = topics[t];
            return (
              <li
                key={c.id}
                className="relative flex space-x-6 py-6 xl:static bg-white p-4 rounded-md my-4"
              >
                <div className="flex-auto">
                  <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">
                    {c.topicTitle}
                  </h3>
                  <div>
                    {c.titles?.map((title: string, index: number) => {
                      return (
                        <div className="flex">
                          {`Week ${index + 1} (${moment(
                            // first day of week since 4/24/2023 (This is when we started classes)
                            c.createdAt + index * 7 * 24 * 60 * 60 * 1000,
                          ).format('MM/DD/YY')}): ${title}`}{' '}
                          <PencilIcon
                            onClick={() => {
                              setEditTitle(title);
                              setEditTopicID(c.id);
                              setEditTopicIndex(index);
                              setEditSlide(c?.slides?.[index] ?? undefined);
                              setEditPhrases(c?.phrases?.[index] ?? []);
                              setEditQuestions(c?.questions?.[index] ?? []);
                              setEditTheme(c?.themes?.[index]);
                              setEditThemeLocation(c?.themeLocations?.[index]);
                              console.log(c?.themeLocations?.[index]);
                            }}
                            className="text-gray-500 cursor-pointer ml-2 w-5 h-5"
                          />
                        </div>
                      );
                    })}
                  </div>
                  <SimpleAddForm
                    label="Weekly Topics"
                    placeholder="type in the title of the topic"
                    onAdd={async (value: string) => {
                      await onUpdateStarted();
                      await addTopicWeeklyTopicTitle(c.id, value);
                      await onUpdateFinished();
                    }}
                  />
                </div>
              </li>
            );
          })}
        </ol>
      </div>
    </>
  );
};
