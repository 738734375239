import StackedCardRadio from '../../../../components/forms/stackedCardRadio/stackedCardRadio';
import {
  HowHearAboutUsData,
  instaPartners,
  tikTokPartners,
  validationSchema,
  youtubePartners,
} from './data';
import { useForm } from '../../../../components/forms/hooks';

import googleIcon from '../../../../images/icons/google.svg';
import instaIcon from '../../../../images/icons/instagram.svg';
import tikTokIcon from '../../../../images/icons/tiktok.svg';
import youtubeIcon from '../../../../images/icons/youtube.svg';
import { DocumentIcon, UserIcon } from '@heroicons/react/20/solid';
import { SimpleBackAndNext } from '../../../../components/buttons/simpleBackAndNext/simpleBackAndNext';
import { OnBoardingPageProps } from '../../OnBoard';
import { useDispatch, useSelector } from 'react-redux';
import { UserData } from '../../../../types/user';
import { useState } from 'react';
import { updateUserData } from '../../../../firebase/users/users';
import HorizontalMessageModal from '../../../../components/modals/horizontalMessageModal/horizontalMessageModal';
import TextInput from '../../../../components/forms/textInput';

export const HowDidYouHearGetStarted = ({
  userID,
  onNextClick,
  onBackClick,
}: OnBoardingPageProps) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openInstaModal, setOpenInstaModal] = useState(false);
  const [openTikTokModal, setOpenTikTokModal] = useState(false);
  const [openYouTubeModal, setOpenYouTubeModal] = useState(false);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );
  const dispatch = useDispatch();

  const formik = useForm<HowHearAboutUsData>({
    initialValues: {
      ...userData,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const payload: Partial<UserData> = {};
        if (values['referMethod']) payload.referMethod = values['referMethod'];
        if (values['instaHandle'])
          payload.referredByHandle = values['instaHandle'];
        if (values['youtubeHandle'])
          payload.referredByHandle = values['youtubeHandle'];
        if (values['tiktokHandle'])
          payload.referredByHandle = values['tiktokHandle'];
        if (payload.referMethod) await updateUserData(userID, payload);
        onNextClick(payload.referMethod, payload.referredByHandle);
        const newUserValue = { ...userData, ...payload };
        dispatch({ type: 'USER_DATA_SET', userData: newUserValue });
      } catch (err) {
        console.log(err);
      }
    },
  });

  const hearAboutChoices = [
    {
      name: 'Instagram',
      icon: <img src={instaIcon} />,
      chosen:
        formik.getSelectProps('referMethod', undefined, true).value ===
        'instagram',
      onClick: () => {
        formik.setFieldValue('referMethod', 'instagram');
        setOpenInstaModal(true);
      },
    },
    {
      name: 'TikTok',
      icon: <img src={tikTokIcon} />,
      chosen:
        formik.getSelectProps('referMethod', undefined, true).value ===
        'tiktok',
      onClick: () => {
        formik.setFieldValue('referMethod', 'tiktok');
        setOpenTikTokModal(true);
      },
    },
    {
      name: 'YouTube',
      icon: <img src={youtubeIcon} />,
      chosen:
        formik.getSelectProps('referMethod', undefined, true).value ===
        'youtube',
      onClick: () => {
        formik.setFieldValue('referMethod', 'youtube');
        setOpenYouTubeModal(true);
      },
    },
    {
      name: 'Google',
      icon: <img src={googleIcon} />,
      chosen:
        formik.getSelectProps('referMethod', undefined, true).value ===
        'google',
      onClick: () => {
        formik.setFieldValue('referMethod', 'google');
      },
    },
    {
      name: 'Blog',
      icon: <DocumentIcon className="text-blue-500" />,
      chosen:
        formik.getSelectProps('referMethod', undefined, true).value === 'blog',
      onClick: () => {
        formik.setFieldValue('referMethod', 'blog');
      },
    },
    {
      name: 'Friend/Colleague',
      icon: <UserIcon className="text-blue-500" />,
      chosen:
        formik.getSelectProps('referMethod', undefined, true).value ===
        'friend-colleague',
      onClick: () => {
        formik.setFieldValue('referMethod', 'friend-colleague');
      },
    },
    {
      name: '💭 Other',
      onClick: () => {
        setOpenModal(true);
      },
    },
  ];

  return (
    <div>
      <HorizontalMessageModal
        mainMessage="How did you hear about us?"
        subMessage="Please type below how you heard about us!"
        theme="default"
        icon="check-mark"
        buttonText="Submit"
        onButtonClick={async () => {
          setLoading(true);
          await formik.submitForm();
          if (formik.getSelectProps('referMethod', undefined, true).value)
            setOpenModal(false);
          setLoading(false);
        }}
        onCloseModal={() => {
          setOpenModal(false);
        }}
        loading={loading}
        isOpen={openModal}
      >
        <div className="mt-6">
          <TextInput
            placeholder="ex) LinkedIn, Podcast"
            value={formik.getSelectProps('referMethod', undefined, true).value}
            onChange={(v: string) => {
              formik.setFieldValue('referMethod', v);
            }}
            error={formik.errors?.referMethod}
            errorMessage={formik.errors?.referMethod}
          />
        </div>
      </HorizontalMessageModal>
      <HorizontalMessageModal
        mainMessage="Which page did you see us from?"
        subMessage="Please select or type the Instagram tag"
        theme="default"
        icon="check-mark"
        buttonText="Submit"
        onButtonClick={async () => {
          setLoading(true);
          await formik.submitForm();
          if (formik.getSelectProps('instaHandle', undefined, true).value)
            setOpenInstaModal(false);
          setLoading(false);
        }}
        onCloseModal={() => {
          setOpenInstaModal(false);
        }}
        loading={loading}
        isOpen={openInstaModal}
      >
        <div className="mt-6">
          <StackedCardRadio
            choices={instaPartners.map((p: any) => {
              p.chosen =
                formik.getSelectProps('instaHandle', undefined, true).value ===
                p.name.slice(1);
              p.onClick = () =>
                formik.setFieldValue('instaHandle', p.name.slice(1));
              return p;
            })}
          />
          <div className="mt-3">
            <TextInput
              title={'Other'}
              value={`@${
                formik.getSelectProps('instaHandle', undefined, true).value ||
                ''
              }`}
              onChange={(v: string) => {
                formik.setFieldValue('instaHandle', v.slice(1).toLowerCase());
              }}
              error={formik.errors?.instaHandle}
              errorMessage={formik.errors?.instaHandle}
            />
          </div>
        </div>
      </HorizontalMessageModal>
      <HorizontalMessageModal
        mainMessage="Which page did you see us from?"
        subMessage="Please select or type the TikTok tag"
        theme="default"
        icon="check-mark"
        buttonText="Submit"
        onButtonClick={async () => {
          setLoading(true);
          await formik.submitForm();
          if (formik.getSelectProps('tiktokHandle', undefined, true).value)
            setOpenTikTokModal(false);
          setLoading(false);
        }}
        onCloseModal={() => {
          setOpenTikTokModal(false);
        }}
        loading={loading}
        isOpen={openTikTokModal}
      >
        <div className="mt-6">
          <StackedCardRadio
            choices={tikTokPartners.map((p: any) => {
              p.chosen =
                formik.getSelectProps('tiktokHandle', undefined, true).value ===
                p.name.slice(1);
              p.onClick = () =>
                formik.setFieldValue('tiktokHandle', p.name.slice(1));
              return p;
            })}
          />
          <div className="mt-3">
            <TextInput
              title={'Other'}
              value={`@${
                formik.getSelectProps('tiktokHandle', undefined, true).value ||
                ''
              }`}
              onChange={(v: string) => {
                formik.setFieldValue('tiktokHandle', v.slice(1).toLowerCase());
              }}
              error={formik.errors?.tiktokHandle}
              errorMessage={formik.errors?.tiktokHandle}
            />
          </div>
        </div>
      </HorizontalMessageModal>
      <HorizontalMessageModal
        mainMessage="Which channel did you see us from?"
        subMessage="Please select or type the YouTube channel name"
        theme="default"
        icon="check-mark"
        buttonText="Submit"
        onButtonClick={async () => {
          setLoading(true);
          await formik.submitForm();
          if (formik.getSelectProps('youtubeHandle', undefined, true).value)
            setOpenYouTubeModal(false);
          setLoading(false);
        }}
        onCloseModal={() => {
          setOpenYouTubeModal(false);
        }}
        loading={loading}
        isOpen={openYouTubeModal}
      >
        <div className="mt-6">
          <StackedCardRadio
            choices={youtubePartners.map((p: any) => {
              p.chosen =
                formik.getSelectProps('youtubeHandle', undefined, true)
                  .value === p.name.slice(1);
              p.onClick = () => formik.setFieldValue('youtubeHandle', p.name);
              return p;
            })}
          />
          <div className="mt-3">
            <TextInput
              title={'Other'}
              value={
                formik.getSelectProps('youtubeHandle', undefined, true).value
              }
              onChange={(v: string) => {
                formik.setFieldValue('youtubeHandle', v.toLowerCase());
              }}
              error={formik.errors?.youtubeHandle}
              errorMessage={formik.errors?.youtubeHandle}
            />
          </div>
        </div>
      </HorizontalMessageModal>
      <div className="text-2xl text-center">How did you hear about us?</div>
      <div className="mt-6 text-center text-sm text-gray-500 mb-8">
        Before we get started, help us out by telling us where you first heard
        about us :)
      </div>
      <div>
        <StackedCardRadio
          choices={hearAboutChoices}
          error={
            formik.getSelectProps('referMethod').error ||
            formik.getSelectProps('instaHandle').error ||
            formik.getSelectProps('tiktokHandle').error ||
            formik.getSelectProps('youtubeHandle').error
          }
        />
      </div>
      <SimpleBackAndNext
        onBack={() => {
          onBackClick?.();
        }}
        onNext={async () => {
          setLoading(true);
          await formik.submitForm();
          setLoading(false);
        }}
        nextLoading={loading}
      />
    </div>
  );
};
