import { Fragment, useEffect, useRef, useState } from 'react';
import { CalendarIcon } from '@heroicons/react/20/solid';
import emptyAvatar from '../../../images/empty-avatar.webp';
import {
  BookingData,
  getClass,
  getSessionBookings,
} from '../../../firebase/subscription/subscription';
import { UserData } from '../../../types/user';
import { returnUserByUID } from '../../../firebase/configuration';
import UserTable, {
  UserTableObject,
} from '../../../components/userTable/userTable';
import { checkIsAdmin } from '../../../util/admin';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  BookOpenIcon,
  LinkIcon,
  PencilSquareIcon,
  UserIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import NoBorderTabs from '../../../components/tabs/noBorderTabs/NoBorderTabs';
import {
  updateClassRecordingLink,
  updateClassSlideLink,
  claimBookingAsACoach,
  pullClassAnHour,
  pushClassAnHour,
  getSessionLimitByLowestStartMili,
  getSessionLimitByHighestStartMili,
  updateClassTitle,
  pullClassADay,
} from '../../../firebase/subscription/classSessions/classSessions';
import {
  addBookingDocumentByEmail,
  adminCancelUserBooking,
  getUserBookings,
  updateBookingAttendance,
} from '../../../firebase/subscription/bookings/bookings';
import { LoadingOverlay } from '../../../components/loading';
import { SimpleAddForm } from '../../../components/forms/SimpleAddForm/SimpleAddForm.';
import {
  addTeacherCefrValuation,
  generateEloClusters,
  getUserTeacherRating,
  getUserTeacherRatingAgg,
} from '../../../api/matching';
import VerticalMessageModal from '../../../components/modals/verticalMessageModal/verticalMessageModal';
import { PrimaryButton } from '../../../components/buttons/primary';
import { SecondaryBlueButton } from '../../../components/buttons/secondaryBlue';
import { Input } from '../../../components/forms/input';
import TextInput from '../../../components/forms/textInput';
import { getClassSessionUserTeacherInput } from '../../../api/classSessions';

export default function ClassSchedule() {
  const currentTime = new Date().valueOf();
  const [sessions, setSessions] = useState<Array<any>>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [page, setPage] = useState(0);

  const [previousLowerLimit, setPreviousLowerLimit] = useState<
    undefined | number
  >();
  const [previousUpperLimit, setPreviousUpperLimit] = useState<
    undefined | number
  >();
  const [seshUpperLimit, setSeshUpperLimit] = useState(currentTime);
  const [seshLowerLimit, setSeshLowerLimit] = useState(currentTime);

  const [recordingLinkToBeEditedID, setRecordingLinkToBeEditedID] = useState<
    string | undefined
  >();
  const [slideLinkToBeEditedID, setSlideLinkToBeEditedID] = useState<
    string | undefined
  >();
  const [titleToBeEditedID, setTitleToBeEditedID] = useState<
    string | undefined
  >();
  const [submitting, setSubmitting] = useState(false);
  const [userClusters, setUserClusters] = useState([]);
  const [userMap, setUserMap] = useState<any>({});
  const [clusterSize, setClusterSize] = useState(3);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  const isAdmin = checkIsAdmin(userData?.email);
  const seshLimit = 10;

  async function generateBreakOutPairings(userList: Array<UserData>) {
    const clusters = await generateEloClusters(
      userList.map((u) => u.id),
      userList.length > 4 ? clusterSize : 2,
    );
    setUserClusters(clusters?.data);
  }

  async function getBookingsForClassSessions() {
    setSubmitting(true);
    let classSessions =
      selectedTab === 0
        ? await getSessionLimitByLowestStartMili(seshLowerLimit, seshLimit)
        : await getSessionLimitByHighestStartMili(seshUpperLimit, seshLimit);
    const newUserMap: any = { ...userMap };
    await Promise.all(
      classSessions.map(async (cs) => {
        const bookings = await getSessionBookings(cs.id);
        const classData: any = await getClass(cs.classID);
        const bookerIDList: Array<string> = [];
        const bookerUserList: Array<UserData> = [];
        await Promise.all(
          bookings.map(async (b) => {
            if (!b.canceled) {
              if (!bookerIDList.includes(b.bookerID)) {
                const user: UserTableObject | undefined =
                  ((await returnUserByUID(b.bookerID)) as UserTableObject) ?? {
                    name: 'Deleted User',
                  };

                const teacherRatings = (
                  await getUserTeacherRating(b.bookerID, b.classSessionID)
                )?.data?.level;

                const teacherRatingAgg = (
                  await getUserTeacherRatingAgg(b.bookerID)
                )?.data;

                console.log(teacherRatingAgg);

                if (teacherRatings) user.teacherEvaluatedLevel = teacherRatings;
                if (teacherRatingAgg)
                  user.teacherEvaluatedLevelAgg = teacherRatingAgg;

                user.bookings = (await getUserBookings(b.bookerID))?.filter(
                  (b: BookingData) => !b.canceled && b.attended,
                );
                user.classCount = user.bookings.length;
                bookerIDList.push(b.bookerID);
                if (user) {
                  const teacherInput = await getClassSessionUserTeacherInput(
                    cs.id,
                    user.id,
                  );

                  console.log(user.id);
                  console.log(teacherInput);
                  user.bookingID = b.id;
                  user.attended = b.attended;
                  user.attendedTime = b.attendedTime;
                  user.improved = teacherInput?.improved ?? [];
                  user.workOn = teacherInput?.workOn ?? [];
                  if (b.correctionID) user.correctionID = b.correctionID;
                  if (b.createdAt) user.bookedAt = b.createdAt;
                  bookerUserList.push(user);
                  newUserMap[user.id] = user;
                }

                console.log(user?.workOn);
              }
              return b;
            }
          }),
        );
        cs.recurringMeetingUrl =
          cs?.recurringMeetingUrl ?? classData.recurringMeetingUrl;
        cs.bookings = bookings;
        cs.bookerList = bookerUserList;
        return bookings;
      }),
    );

    classSessions.sort((a, b) => {
      return selectedTab === 0
        ? a.startMili - b.startMili
        : b.startMili - a.startMili;
    });

    setUserMap(newUserMap);
    setSessions(classSessions);
    setSubmitting(false);
  }

  useEffect(() => {
    getBookingsForClassSessions();
  }, []);

  useEffect(() => {
    setPreviousLowerLimit(undefined);
    setPreviousUpperLimit(undefined);
    setSeshLowerLimit(currentTime);
    setSeshUpperLimit(currentTime);
    // updatePastOrUpcomingSessions();
  }, [selectedTab]);

  useEffect(() => {
    getBookingsForClassSessions();
  }, [seshLowerLimit, seshUpperLimit]);

  return (
    <div>
      <LoadingOverlay enabled={submitting} />
      <VerticalMessageModal
        mainMessage=""
        subMessage=""
        theme="default"
        isOpen={userClusters?.length > 0}
        onCloseModal={() => {
          setUserClusters([]);
        }}
      >
        <div className="overflow-scroll">
          {userClusters?.map((c: Array<string>, i: number) => {
            const userList = c.map((id: string) => {
              return userMap[id];
            });
            return (
              <div className="mt-2 text-start">
                <div className="text-start font-bold">{`Room ${i + 1}`}</div>
                <UserTable name cefr userList={userList} />
              </div>
            );
          })}
        </div>
      </VerticalMessageModal>
      {isAdmin ? (
        <>
          <NoBorderTabs
            tabs={['Upcoming Classes', 'Past Classes']}
            selectedIndex={selectedTab}
            onTabClicked={(index: number) => {
              setSelectedTab(index);
            }}
            onNextClick={
              sessions.length === seshLimit
                ? () => {
                    if (selectedTab === 0) {
                      setPreviousLowerLimit(seshLowerLimit);
                      setSeshLowerLimit(
                        sessions[sessions.length - 1].startMili,
                      );
                    } else {
                      setPreviousUpperLimit(seshUpperLimit);
                      setSeshUpperLimit(
                        sessions[sessions.length - 1].startMili,
                      );
                    }
                    setPage(page + 1);
                  }
                : undefined
            }
            onBackClick={
              previousLowerLimit || previousUpperLimit
                ? () => {
                    if (selectedTab === 0) {
                      if (previousLowerLimit)
                        setSeshLowerLimit(previousLowerLimit);
                    } else {
                      if (previousUpperLimit)
                        setSeshUpperLimit(previousUpperLimit);
                    }
                    setPage(page - 1);
                  }
                : undefined
            }
          />
          <div className="max-w-[400px]">
            <TextInput
              title={'cluster size'}
              value={clusterSize}
              onChange={(v: string) => setClusterSize(parseInt(v))}
            />
          </div>
          <div className="">
            <ol className="mt-4 divide-y divide-gray-100 text-sm">
              {sessions
                ?.filter((s) => s.bookerList.length > 0)
                ?.map((c: BookingData) => (
                  <li
                    key={c.id}
                    className="relative flex space-x-6 py-6 xl:static bg-white my-8 rounded-md p-4  overflow-x-scroll md:max-w-full"
                  >
                    <div className="flex-auto">
                      <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">
                        <div className="flex">
                          {!titleToBeEditedID ? (
                            <span className="mr-2">{c.title}</span>
                          ) : (
                            <dd>
                              <div>
                                <label htmlFor="email" className="sr-only">
                                  Link
                                </label>
                                <input
                                  type="text"
                                  name="title"
                                  id="title"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-immigo sm:text-sm sm:leading-6"
                                  placeholder=""
                                  defaultValue={c.title}
                                  onBlur={async (e) => {
                                    if (c.id && e?.target?.value) {
                                      await updateClassTitle(
                                        c.id,
                                        e.target.value,
                                      );
                                      c.recording = e.target.value;
                                    }
                                    setTitleToBeEditedID(undefined);
                                  }}
                                />
                              </div>
                            </dd>
                          )}
                          <div
                            className="hover:text-blue-immigo cursor-pointer text-gray-500 font-normal"
                            onClick={() => {
                              if (titleToBeEditedID !== c.title)
                                setTitleToBeEditedID(c.id);
                              else setTitleToBeEditedID(undefined);
                            }}
                          >
                            Edit
                          </div>
                        </div>
                      </h3>
                      <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                        <div className="flex items-start space-x-3">
                          <dt className="mt-0.5">
                            <span className="sr-only">Date</span>
                            <CalendarIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </dt>
                          <dd>
                            <time dateTime={moment(c.startMili).format()}>
                              {`${moment(c.startMili)
                                .local()
                                .format(
                                  'MMMM Do, YYYY [at] hh:mm A',
                                )} - ${moment(c.startMili)
                                .add(c.durationMili)
                                .local()
                                .format('hh:mm A')}`}
                            </time>
                          </dd>
                        </div>
                        {userData?.email === 'info@immigo.io' ? (
                          <>
                            <div
                              className="ml-4 cursor-pointer"
                              onClick={async () => {
                                setSubmitting(true);
                                if (c.id) await pullClassADay(c.id);
                                setSubmitting(false);
                              }}
                            >
                              (-)
                            </div>
                            <div
                              className="ml-2 cursor-pointer"
                              onClick={async () => {
                                setSubmitting(true);
                                if (c.id) await pullClassAnHour(c.id);
                                setSubmitting(false);
                              }}
                            >
                              -
                            </div>
                            <div
                              className="ml-2 cursor-pointer"
                              onClick={async () => {
                                setSubmitting(true);
                                if (c.id) await pushClassAnHour(c.id);
                                setSubmitting(false);
                              }}
                            >
                              +
                            </div>
                          </>
                        ) : null}
                      </dl>
                      <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                        <div className="flex items-start space-x-3">
                          <dt className="mt-0.5">
                            <span className="sr-only">Date</span>
                            <LinkIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </dt>
                          <dd className="hover:text-blue-immigo cursor-pointer">
                            <a
                              href={c.recurringMeetingUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {c.recurringMeetingUrl}
                            </a>
                          </dd>
                        </div>
                      </dl>
                      <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                        <div className="flex items-start space-x-3">
                          <dt className="mt-0.5">
                            <span className="sr-only">Survey</span>
                            <PencilSquareIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </dt>
                          <dd className="hover:text-blue-immigo cursor-pointer">
                            <a
                              href={`${window.location.origin}/class/${c.id}?page=survey`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {`${window.location.origin}/class/${c.id}?page=survey`}
                            </a>
                          </dd>
                        </div>
                      </dl>
                      <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                        <div className="flex items-start space-x-3">
                          <dt className="mt-0.5">
                            <span className="sr-only">Date</span>
                            <BookOpenIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </dt>
                          {slideLinkToBeEditedID !== c.id ? (
                            <dd className="hover:text-blue-immigo cursor-pointer truncate max-w-md">
                              {c.slide ?? c.cleanSlide ? (
                                <a
                                  href={c.slide ?? c.cleanSlide}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {c.slide ?? c.cleanSlide}
                                </a>
                              ) : (
                                'Slide link missing'
                              )}
                            </dd>
                          ) : (
                            <dd>
                              <div>
                                <label htmlFor="email" className="sr-only">
                                  Link
                                </label>
                                <input
                                  type="link"
                                  name="link"
                                  id="link"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-immigo sm:text-sm sm:leading-6"
                                  placeholder=""
                                  onBlur={async (e) => {
                                    if (c.id && e?.target?.value) {
                                      await updateClassSlideLink(
                                        c.id,
                                        e.target.value,
                                      );
                                      c.slide = e.target.value;
                                    }
                                    setSlideLinkToBeEditedID(undefined);
                                  }}
                                />
                              </div>
                            </dd>
                          )}
                          <div
                            className="hover:text-blue-immigo cursor-pointer"
                            onClick={() => {
                              if (recordingLinkToBeEditedID !== c.id)
                                setSlideLinkToBeEditedID(c.id);
                              else setSlideLinkToBeEditedID(undefined);
                            }}
                          >
                            Edit
                          </div>
                        </div>
                      </dl>
                      <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                        <div className="flex items-start space-x-3">
                          <dt className="mt-0.5">
                            <span className="sr-only">Date</span>
                            <VideoCameraIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </dt>
                          {recordingLinkToBeEditedID !== c.id ? (
                            <dd className="hover:text-blue-immigo cursor-pointer truncate max-w-md">
                              {c.recording ? (
                                <a
                                  href={c.recording}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {c.recording}
                                </a>
                              ) : (
                                'Recording link missing'
                              )}
                            </dd>
                          ) : (
                            <dd>
                              <div>
                                <label htmlFor="email" className="sr-only">
                                  Link
                                </label>
                                <input
                                  type="link"
                                  name="link"
                                  id="link"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-immigo sm:text-sm sm:leading-6"
                                  placeholder=""
                                  onBlur={async (e) => {
                                    if (c.id && e?.target?.value) {
                                      await updateClassRecordingLink(
                                        c.id,
                                        e.target.value,
                                      );
                                      c.recording = e.target.value;
                                    }
                                    setRecordingLinkToBeEditedID(undefined);
                                  }}
                                />
                              </div>
                            </dd>
                          )}
                          <div
                            className="hover:text-blue-immigo cursor-pointer"
                            onClick={() => {
                              if (recordingLinkToBeEditedID !== c.id)
                                setRecordingLinkToBeEditedID(c.id);
                              else setRecordingLinkToBeEditedID(undefined);
                            }}
                          >
                            Edit
                          </div>
                        </div>
                      </dl>
                      <dl className="mt-2 flex items-center text-gray-500 xl:flex-row">
                        <div className="flex items-center space-x-3">
                          <dt className="mt-0.5">
                            <span className="sr-only">Coach</span>
                            <UserIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </dt>
                          {c.teacher ? (
                            <dd>
                              <div className="flex items-center">
                                <div className="h-11 w-11 flex-shrink-0">
                                  <img
                                    key={
                                      (c.teacher.profilePictureLink ??
                                        'avatar') + emptyAvatar
                                    }
                                    className="mr-2 inline-block h-10 w-10 rounded-full ring-1 ring-gray-200 object-cover object-center bg-white"
                                    src={
                                      c.teacher.profilePictureLink ??
                                      emptyAvatar
                                    }
                                    alt={
                                      (c.teacher.profilePictureLink ??
                                        'avatar') + emptyAvatar
                                    }
                                  />
                                </div>
                                <div>{c.teacher.name}</div>
                              </div>
                            </dd>
                          ) : (
                            <dd>No coach assigned yet</dd>
                          )}
                          <div
                            className="hover:text-blue-immigo cursor-pointer"
                            onClick={async () => {
                              setSubmitting(true);
                              console.log(userData?.id);
                              if (userData?.id && c.id)
                                await claimBookingAsACoach(userData.id, c.id);
                              c.teacher = {
                                name: userData.name,
                                profilePictureLink: userData.profilePictureLink,
                                id: userData.id,
                              };
                              setSubmitting(false);
                            }}
                          >
                            Claim
                          </div>
                        </div>
                      </dl>

                      {c?.bookerList ? (
                        <div className="mt-8">
                          <div className="max-w-[200px] ml-auto mb-2 flex">
                            <PrimaryButton
                              text="Get room assignment"
                              className="cursor-pointer"
                              onClick={async () => {
                                if (c.bookerList)
                                  await generateBreakOutPairings(c.bookerList);
                              }}
                            />
                          </div>
                          <div>
                            <div className="max-w-[200px] ml-auto mb-2">
                              <SecondaryBlueButton
                                text="Get active assignment"
                                onClick={async () => {
                                  if (c.bookerList)
                                    await generateBreakOutPairings(
                                      c.bookerList.filter(
                                        (b) => (b as UserTableObject).attended,
                                      ) ?? [],
                                    );
                                }}
                              />
                            </div>
                          </div>
                          <div className="text-md">Attendee List</div>
                          <UserTable
                            name
                            cefr
                            job
                            country
                            attended
                            allowDeleteBooking
                            allowMarkAttendance
                            phone
                            classCount
                            correctionLink
                            createdAt
                            evaluate
                            sessionId={c.id}
                            accessorId={userData?.id}
                            userList={c.bookerList.map((b: UserData) => {
                              const booker = b as UserTableObject;
                              booker.onCefrChosen = async (level: string) => {
                                setSubmitting(true);
                                if (c.id)
                                  await addTeacherCefrValuation(
                                    booker.id,
                                    userData.id,
                                    c.id,
                                    level,
                                  );
                                setSubmitting(false);
                                console.log(level);
                              };
                              booker.onRemoveClick = async () => {
                                setSubmitting(true);
                                if (booker?.bookingID)
                                  await adminCancelUserBooking(
                                    booker?.bookingID,
                                  );
                                c.bookerList = c.bookerList?.filter(
                                  (user: UserData) => b.id !== user.id,
                                );
                                setSubmitting(false);
                              };
                              booker.onUserAttendanceUpdate = async (
                                attended: boolean,
                              ) => {
                                setSubmitting(true);
                                if (booker?.bookingID)
                                  await updateBookingAttendance(
                                    booker.bookingID,
                                    attended,
                                  );
                                booker.attended = attended;
                                setSubmitting(false);
                              };
                              return booker;
                            })}
                          />
                        </div>
                      ) : null}
                      <div className="mt-6 text-md mb-4">
                        Add student by email
                      </div>
                      <SimpleAddForm
                        label="add-student"
                        placeholder="example@immigo.io"
                        onAdd={async (value: string) => {
                          setSubmitting(true);
                          const { user, bookingIDs } =
                            await addBookingDocumentByEmail(
                              c.classID,
                              c.startMili,
                              c.durationMili,
                              value,
                              1,
                              'admin', // Added by admin
                            );
                          if (bookingIDs?.[0])
                            (user as UserTableObject).bookingID =
                              bookingIDs?.[0];
                          if (c.bookerList) c.bookerList.push(user as UserData);
                          setSubmitting(false);
                        }}
                      />
                    </div>
                  </li>
                ))}
            </ol>
          </div>
        </>
      ) : null}
    </div>
  );
}
