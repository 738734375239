import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler);

const data = {
  labels: ['Grammar', 'Vocabulary', 'Pronunciation', 'Fluency', 'Filler Words'],
  datasets: [
    {
      label: '# of Votes',
      data: [54, 71, 45, 61, 20],
      backgroundColor: 'rgba(19, 199, 255, 0.5)',
      borderColor: 'rgba(19, 199, 255, 0.5)',
      borderWidth: 1,
    },
  ],
};

export const RadialStat = (props: {}) => {
  return (
    <div className="bg-gradient-to-r from-slate-900 to-slate-700 rounded-md px-8 py-6">
      <div className="text-white  flex justify-center flex-col items-center">
        <div className="font-bold flex mb-2 text-2xl">
          Intermediate <span className="text-blue-500 ml-1">B1</span>
        </div>
        <div>
          <span className="text-white font-bold">50</span>
          <span className="text-gray-400 font-semibold"> out of 100</span>
        </div>
      </div>
      <Radar
        data={data}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
          },
          scales: {
            r: {
              grid: {
                color: 'white',
              },
              pointLabels: {
                color: 'white',
                font: {
                  size: 12,
                  weight: 'bold',
                  family: 'Roboto',
                },
              },
              ticks: {
                color: 'rgba(19, 199, 255, 0)',
                major: {
                  enabled: false,
                },
                textStrokeColor: 'rgba(19, 199, 255, 0)',
                showLabelBackdrop: false,
              },
            },
          },
        }}
      />
    </div>
  );
};
