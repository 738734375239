import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleBackAndNext } from '../../../../components/buttons/simpleBackAndNext/simpleBackAndNext';
import { useForm } from '../../../../components/forms/hooks';
import { Input } from '../../../../components/forms/input';
import { Radio } from '../../../../components/forms/radio';
import { Select } from '../../../../components/forms/select';
import { updateUserData } from '../../../../firebase/users/users';
import { UserData } from '../../../../types/user';
import { removeUndefinedProperties } from '../../../../util/data';
import { arrayAsSelectOptions, yesNoOptions } from '../../../../util/forms';
import { OnBoardingPageProps } from '../../OnBoard';
import {
  AdditionalInformationData,
  kindsOfWork,
  relationshipStatuses,
  validationSchema,
} from './data';
import TextInput from '../../../../components/forms/textInput';
import { CustomField } from '../../../../components/forms/custom-field';

export const AdditionalInformation = ({
  userID,
  onNextClick,
  onBackClick,
}: OnBoardingPageProps) => {
  const [loading, setLoading] = useState(false);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );
  const dispatch = useDispatch();

  const formik = useForm<AdditionalInformationData>({
    initialValues: { ...userData },
    validationSchema,
    onSubmit: async (values) => {
      await updateUserData(userID, removeUndefinedProperties({ ...values }));
      const newUserValue = { ...userData, ...values };
      dispatch({ type: 'USER_DATA_SET', userData: newUserValue });
      onNextClick();
    },
  });

  return (
    <div>
      <div className="text-2xl text-center">Tell us more about yourself!</div>
      <div className="mt-6 text-center text-sm text-gray-500">
        You are almost done! Let us get to understand you a bit better so that
        we can deliver more customized learning experience for you.
      </div>
      <div className="mt-6">
        <Input
          label={'Age'}
          description={'How old are you?'}
          min={0}
          type={'number'}
          {...formik.getFieldProps('age')}
        />
      </div>
      <div className="mt-6">
        <Select
          label={'What is your relationship status?'}
          {...formik.getSelectProps('relationshipStatus')}
          options={arrayAsSelectOptions(relationshipStatuses)}
        />
      </div>
      <div className="mt-6">
        <Radio
          label={'Do you have kids?'}
          {...formik.getRadioProps(
            'haveKids',
            (value) => (value ? 'yes' : 'no'),
            (newValue) => {
              formik.setFieldValue('haveKids', newValue === 'yes');
            },
          )}
          options={yesNoOptions}
        />
      </div>
      <div className="mt-6">
        <Select
          label={'What kind of work do you do?'}
          {...formik.getSelectProps('kindOfWork')}
          options={arrayAsSelectOptions(kindsOfWork)}
        />
      </div>
      <div className="mt-6">
        <Input
          label={'What exactly is your occupation?'}
          {...formik.getFieldProps('occupation')}
        />
      </div>
      <div className="mt-6">
        <Radio
          label={
            'Do you wish to be contacted by other members of Immigo community if they want to practice English with you?'
          }
          {...formik.getRadioProps(
            'wishToBeContacted',
            (value) => (value ? 'yes' : 'no'),
            (newValue) => {
              formik.setFieldValue('wishToBeContacted', newValue === 'yes');
            },
          )}
          options={yesNoOptions}
        />
      </div>
      <div className="mt-6">
        <CustomField
          label={'Whatsapp (or WeChat) Phone number (with country code with +)'}
          description={
            'Your phone number will only be used internally to send you class related information.'
          }
          {...formik.getFieldProps('phoneNumber')}
        >
          <TextInput
            value={formik.values.phoneNumber}
            onChange={(value: string) => {
              if (value?.[0] !== '+') value = '+' + value;
              formik.setFieldValue('phoneNumber', value);
            }}
          />
        </CustomField>
      </div>

      <div className="mt-6">
        <Input
          asTextArea
          label={
            '💙 What would you like your Immigo community members to know about you?'
          }
          {...formik.getFieldProps('infoToKnow')}
        />
      </div>
      <SimpleBackAndNext
        onBack={() => {
          onBackClick?.();
        }}
        onNext={async () => {
          setLoading(true);
          await formik.submitForm();
          setLoading(false);
        }}
        nextLoading={loading}
      />
    </div>
  );
};
