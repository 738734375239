import { useEffect, useRef, useState } from 'react';
import { PrimaryButton } from '../../../../components/buttons/primary';
import { BackgroundRecorder } from '../../../../components/backgroundProcess/recorder';
import { BackgroundTranscriber } from '../../../../components/backgroundProcess/transcriber';
import {
  addTranscript,
  createCorrectionDocument,
  getCorrectionsFromBooking,
  updateFormattedTranscriptsAndAddCorrections,
} from '../../../../firebase/feedback/correction';
import { Correction, CorrectionDocument } from '../../../../types/feedback';
import { ClassPrepTranscribePage } from './transcribePage';
import { ClassPrepFinishedClassPage } from './finishedClassPage.tsx';
import { SpeechMaticTranscriptResult } from '../../../../types/speechmatic';
import { uploadClassVoiceRecording } from '../../../../firebase/fileHandler/fileHandler';
import { LoadingOverlay } from '../../../../components/loading';
import { trackClassLinkRedirect } from '../../analytics';
import { addBookingLog } from '../../../../firebase/subscription/bookings/bookings';
import RecordRTC from 'recordrtc';

export type ClassPrepSetUpState =
  | 'transcribing'
  | 'endofclass'
  | 'setup'
  | 'survey';

// Transcriber ready -> Recorder ready

export const ClassPrepAllowMicPage = (props: {
  onReadyClick: Function;
  onUploadFinished: Function;
  state: ClassPrepSetUpState;
  defaultAudioInputDeviceId?: string;
  classSessionID?: string;
  bookerID?: string;
  bookingID?: string;
  corporate?: boolean;
}) => {
  const {
    onReadyClick,
    bookerID,
    bookingID,
    state,
    defaultAudioInputDeviceId,
    classSessionID,
    onUploadFinished,
    corporate,
  } = props;

  const [recorderReady, setRecorderReady] = useState(false);
  const [transcriberReady, setTranscriberReady] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [isTranscribing, setIsTranscribing] = useState(true); // State transcribing by default. We will wait for the transcriber before recording.
  const [isReadyToInitializeRecorder, setIsReadyToInitializeRecorder] =
    useState(false); // Wait until transcriber is ready
  const [isRecording, setIsRecording] = useState(false);

  const [correctionDoc, setCorrectionDoc] = useState<
    CorrectionDocument | undefined
  >();
  const socketRef = useRef<undefined | WebSocket>();

  useEffect(() => {
    if (transcriberReady) {
      setIsReadyToInitializeRecorder(true); // Get microphone ready when connection is established
    }
  }, [transcriberReady]);

  useEffect(() => {
    if (state === 'endofclass') {
      // If class ended
      setIsTranscribing(false);
      setIsRecording(false); //Stop transcribing and recording once class ends
    } else if (state === 'transcribing') {
      setIsRecording(true); // Start recording
      setIsTranscribing(true);
    }
  }, [state]);

  async function log(l: string) {
    if (bookingID) await addBookingLog(bookingID, l, 'transcription');
  }

  async function onTranscriberReady(socketClient: WebSocket | undefined) {
    if (!bookerID || !bookingID) return;
    socketRef.current = socketClient; // Set the socket client

    let correctionDoc = (await getCorrectionsFromBooking(
      bookingID,
    )) as CorrectionDocument; // Try to retrieve correction document if already exists

    if (!correctionDoc) {
      correctionDoc = await createCorrectionDocument(
        bookerID,
        bookingID,
        corporate,
      ); // Create new document if correction document doesn't exist already
    }

    if (!correctionDoc) {
      await log(`Error: onTranscriberReady : Correction Doc is missing`);
    }

    if (!socketClient) {
      await log(`Error: onTranscriberReady : Socket Client is ${socketClient}`);
    }

    await log('Transcriber ready');
    console.log('Transcriber ready');
    setCorrectionDoc(correctionDoc);
    setTranscriberReady(true);
  }

  async function onIncomingText(
    currentText: string,
    incomingText: string,
    onProcessFinished: Function,
  ) {
    try {
      // Need to include question mark
      const punctReg = new RegExp(/[?.!,]/);
      let originalText = currentText;
      let newTranscript = incomingText[0]?.match(punctReg)
        ? originalText.trim() + incomingText
        : originalText + incomingText;

      if (incomingText && correctionDoc?.id) {
        await addTranscript(correctionDoc.id, incomingText);
      } else {
        if (!correctionDoc?.id)
          await log(
            `Error: Add incoming text error : correctionDocID missing ${incomingText}`,
          );
        if (!incomingText)
          await log(
            `Error: Add incoming text error : incomingText missing ${incomingText}`,
          );
      }

      onProcessFinished(newTranscript);
    } catch (err) {
      await log(`Error: Add transcript error : ${err}`);
    }
  }

  async function onIncomingResults(
    incomingResults: Array<SpeechMaticTranscriptResult>,
    formattedResults?: Array<string>,
  ) {
    try {
      if (incomingResults && correctionDoc?.id) {
        // console.log(incomingResults)
        console.log('result');
        console.log(incomingResults);
        await updateFormattedTranscriptsAndAddCorrections(
          correctionDoc.id,
          incomingResults,
          formattedResults,
        );
        await log(`Incoming result added ${incomingResults?.length}`);
      } else {
        if (!correctionDoc?.id)
          await log(
            `Error: Add incoming result error : correctionDocID missing`,
          );
        if (!incomingResults)
          await log(`Error: Add incoming result error : no incoming result`);
      }
    } catch (err) {
      await log(`Error: Add incoming result faield error : ${err}`);
    }
  }
  const ready = transcriberReady && recorderReady;

  function renderPage() {
    switch (state) {
      case 'transcribing':
        return <ClassPrepTranscribePage />;
      case 'endofclass':
        return <ClassPrepFinishedClassPage />;
      default:
        return (
          <>
            <div className="md:text-3xl text-xl">
              Enable your microphone access!
            </div>
            <div className="text-gray-500 md:text-xl text-sm mt-4">
              Please enable microphone access so that our teachers can hear you
              and we can give you better feedback!
            </div>
            <div className="my-12 text-8xl">🎤</div>
            <div className="max-w-[350px]">
              <PrimaryButton
                text={
                  ready
                    ? "I'm ready for class!"
                    : 'Waiting for microphone access'
                }
                disabled={!ready}
                loadingWithText={!ready}
                onClick={onReadyClick}
              />
            </div>
          </>
        );
    }
  }

  return (
    <div className="justify-center items-center flex flex-col max-w-[500px] text-center">
      <LoadingOverlay enabled={uploading} loadingMessage={loadingText} />
      {
        bookerID && bookingID ? (
          <BackgroundTranscriber
            isTranscribing={isTranscribing}
            isRecording={isRecording}
            onConnectionCreated={onTranscriberReady}
            onIncomingText={onIncomingText}
            onIncomingResults={onIncomingResults}
            onError={async (err: any) => {
              await log(`Transcriber error : ${err}`);
            }}
          />
        ) : null // Only initialize transcriber when we have both bookerID and bookingID
      }
      <BackgroundRecorder
        isReadyToInitializeRecorder={isReadyToInitializeRecorder}
        isRecording={isRecording}
        onRecorderReady={async (tracks: Array<MediaStreamTrack>) => {
          let deviceConnected = '';
          tracks.forEach((track) => (deviceConnected += `${track.label} |`));
          console.log(`Recorder ready: device connected ${deviceConnected}`);
          await log(`Recorder ready: device connected ${deviceConnected}`);
          setRecorderReady(true);
        }}
        defaultDeviceId={defaultAudioInputDeviceId}
        onStartRecording={async () => {
          await log(`Recorder recording`);
          setIsRecording(true);
        }}
        onDenied={async (err: string) => {
          await log(`Recorder denied recording ${err}`);
          trackClassLinkRedirect(
            'Upload class voice recording encountered error',
            {
              error: err,
            },
          );
        }}
        onDataAvailable={(data: Blob) => {
          if (socketRef?.current) {
            socketRef?.current?.send(data);
          }
        }}
        onStopRecording={async (file: File | undefined) => {
          setUploading(true);
          await log(`Recording stopped`);
          trackClassLinkRedirect(
            'Stopped recording triggered for class voice recording',
            {
              sessionID: classSessionID,
              bookingID: bookingID,
              bookerID: bookerID,
            },
          );
          if (file && bookerID && bookingID && classSessionID) {
            trackClassLinkRedirect(
              'Uploading class voice recording initiated',
              {
                sessionID: classSessionID,
                bookingID: bookingID,
                bookerID: bookerID,
                fileSize: file.size,
              },
            );
            try {
              await log('Trying to upload voice recording');
              await uploadClassVoiceRecording(
                file,
                classSessionID,
                bookingID,
                bookerID,
                (progress: number) => {
                  setLoadingText(
                    'Uploading in progress ... ' + Math.round(progress) + '%',
                  );
                },
                async () => {
                  await log(
                    `Success: Voice recording upload success size: ${file.size}`,
                  );
                  trackClassLinkRedirect(
                    'Upload class voice recording succeeded',
                    {
                      sessionID: classSessionID,
                      bookingID: bookingID,
                      bookerID: bookerID,
                      fileSize: file.size,
                    },
                  );
                },
                (progress: number) => {},
                async (err: any) => {
                  await log(
                    `Error: Voice recording upload failed size: ${file.size} error: ${err}`,
                  );
                  trackClassLinkRedirect(
                    'Upload class voice recording encountered error',
                    {
                      sessionID: classSessionID,
                      bookingID: bookingID,
                      bookerID: bookerID,
                      fileSize: file.size,
                      error: err,
                    },
                  );
                },
              );
            } catch (e: any) {
              await log(
                `Error: Voice recording upload failed size: ${file.size} error: ${e}`,
              );
              trackClassLinkRedirect(
                'Upload class voice recording encountered error',
                {
                  sessionID: classSessionID,
                  bookingID: bookingID,
                  bookerID: bookerID,
                  fileSize: file.size,
                  error: e,
                },
              );
            }
          }
          setUploading(false);
          setIsTranscribing(false);
          onUploadFinished();
        }}
      />
      {renderPage()}
    </div>
  );
};
