import { ActivityEventContainer } from './activityEventContainer';
import { useEffect, useState } from 'react';
import { getPast7DaysUserActivityFeed } from '../../../firebase/social/social';
import { ActivityEvent } from '../../../types/social';
import moment from 'moment-timezone';
import { activityTypeToString } from '../../../util/social/activityFeed';
import { getFirstNameFromFullName } from '../../../util/helper';
import { ActivityFeedLoader } from './activityFeedLoader';

export default function ActivityFeedContainer(props: {
  uid: string;
  onActivityAvatarClicked?: Function;
}) {
  const { uid, onActivityAvatarClicked } = props;

  const limit = 5;

  const [activityFeed, setActivityFeed] = useState<Array<ActivityEvent>>([]);
  const [loading, setLoading] = useState(true);

  async function updateUserActivityFeed() {
    setLoading(true);
    const activityFeed = await getPast7DaysUserActivityFeed(uid);
    setActivityFeed(activityFeed?.slice(0, limit));
    setLoading(false);
  }

  useEffect(() => {
    if (uid) updateUserActivityFeed();
  }, [uid]);

  return (
    <div className="py-6 max-h-[250px] overflow-y-scroll">
      {!loading ? (
        <ul role="list" className="-mb-8">
          {activityFeed?.length ? (
            activityFeed.map((event, eventIdx) => (
              <ActivityEventContainer
                id={event.id}
                uid={event.uid}
                iconPictureURL={
                  event.subject
                    ? event.subject?.profilePictureLink
                    : event.profilePictureLink
                }
                date={moment(event.createdAt).fromNow(true)}
                content={activityTypeToString(
                  event.uid === uid
                    ? 'You'
                    : getFirstNameFromFullName(event.name),
                  event.activityType,
                  event.subject?.name
                    ? getFirstNameFromFullName(event.subject.name)
                    : undefined,
                  event.content,
                )}
                timelineLength={activityFeed.length}
                eventIndex={eventIdx}
                onActivityAvatarClicked={onActivityAvatarClicked}
              />
            ))
          ) : (
            <div className="min-h-[100px] w-full flex">
              <div className="mx-auto my-auto text-center">
                <div className="text-xl text-gray-500">
                  Your activity feed is empty
                </div>
                <div className="text-sm text-gray-400 max-w-[300px]">
                  Start sending reactions to your peers to see what your friends
                  are upto!
                </div>
              </div>
            </div>
          )}
        </ul>
      ) : (
        <ActivityFeedLoader />
      )}
    </div>
  );
}
