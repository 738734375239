import { useEffect, useState } from 'react';
import WeekBookingCalendar from '../../../features/Booking/weekBookingCalendar';
import { getAllClasses } from '../../../firebase/subscription/classes/classes';
import { ClassType } from '../../../types/class';
import VerticalMessageModal from '../../modals/verticalMessageModal/verticalMessageModal';
import { BookingEventObject } from '../../../features/Booking/bookingEventContainer';
import { LoadingOverlay } from '../../loading';
import { createBookingDocument } from '../../../firebase/subscription/bookings/bookings';
import {
  addAvailableClassesToUser,
  BookingData,
} from '../../../firebase/subscription/subscription';
import { weekInMili } from '../../../usefuldata/mili';

export const SpreadBookingModal = (props: {
  onOpen: Function;
  onClose: Function;
  open: boolean;
  availableClassesNumber: number;
  userID: string;
  unlimited?: boolean;
  bookedClassSessionIds: Array<string>;
  bookings: Array<BookingData>;
  onBooking: Function;
  classTypeFilter?: ClassType;
}) => {
  const {
    onOpen,
    onClose,
    open,
    classTypeFilter,
    userID,
    unlimited,
    bookedClassSessionIds,
    bookings,
    onBooking,
    availableClassesNumber,
  } = props;
  const [classes, setClassList] = useState<Array<BookingEventObject>>([]);
  const [chosenSchedule, setChosenSchedule] = useState<
    Array<BookingEventObject>
  >([]);
  const [classesLoading, setClassesLoading] = useState(false);
  const [error, setError] = useState('');

  async function updateClasses() {
    const classes = (await getAllClasses()) as Array<BookingEventObject>;
    const combinedClasses: Array<BookingEventObject> = classTypeFilter
      ? [...classes].filter((c) => {
          return c.tag === classTypeFilter;
        })
      : [...classes];

    combinedClasses.forEach((c) => {
      c.show = true;
    });

    setClassList(combinedClasses);
  }

  useEffect(() => {
    updateClasses();
  }, []);

  useEffect(() => {
    if (classTypeFilter) updateClasses();
  }, [classTypeFilter]);

  useEffect(() => {
    setError('');
  }, [chosenSchedule]);

  async function onBookingSubmit() {
    if (chosenSchedule.length === 0) {
      setError('Select at least one class to submit');
      return;
    }

    if (
      (!availableClassesNumber && !unlimited) ||
      (availableClassesNumber < chosenSchedule?.length && !unlimited)
    ) {
      setError("You don't have enough class credits");
      return;
    }

    let avoidFlag = false;

    chosenSchedule.forEach((schedule) => {
      if (schedule.startMili > new Date().valueOf() + weekInMili * 2) {
        setError('Please book classes happening within 2 weeks from now!');
        avoidFlag = true;
        return;
      }
    });

    if (avoidFlag) return;

    setClassesLoading(true);
    let allBookings: any[] = [];
    await Promise.all(
      chosenSchedule.map(async (booking) => {
        if (booking?.id) {
          const bookings = await createBookingDocument(
            booking?.id,
            booking?.startMili,
            booking?.durationMili,
            userID,
            1,
          );
          allBookings = allBookings.concat(bookings);
          return bookings;
        }
      }),
    );
    await addAvailableClassesToUser(userID, -allBookings.length); // Subtract the booked courses
    setChosenSchedule([]);
    setClassesLoading(false);
    onBooking();
  }

  useEffect(() => {
    setError('');
    console.log(chosenSchedule);
  }, [chosenSchedule]);

  console.log(bookings);
  return (
    <div>
      <LoadingOverlay enabled={classesLoading} loadingMessage="" />
      <VerticalMessageModal
        mainMessage=""
        buttonText={'Submit'}
        subMessage=""
        icon="star"
        theme="default"
        isOpen={open}
        onModalOpen={onOpen}
        onButtonClick={onBookingSubmit}
        onCloseModal={onClose}
        customWidthStyle={'md:w-4/5 w-full'}
        stayOpenOnPrimaryClick
      >
        {classTypeFilter
          ? `Suggested ${
              classTypeFilter == 'idiom' ? 'immersion' : classTypeFilter
            } classes`
          : null}
        <div className="max-h-[600px] overflow-y-scroll">
          <WeekBookingCalendar
            chosenSchedule={chosenSchedule}
            setChosenSchedule={setChosenSchedule}
            bookedClassSessionIds={bookedClassSessionIds}
            userBookings={bookings}
            events={classes}
            setLoading={setClassesLoading}
            cefr={'B1'}
          />
        </div>
        {error ? (
          <div className="text-red-warning text-xs mt-4">{error}</div>
        ) : null}
      </VerticalMessageModal>
    </div>
  );
};
