import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Played demo scenario'
  | 'Demo first message sent'
  | 'Demo second message sent'
  | 'Demo clicked mic button'
  | 'Demo transcription sent'
  | 'Demo correction made';

type DemoData = {
  text?: string;
  explanation?: string;
  original?: string;
  fixed?: string;
};

export const trackAIDemoPage = (event: Events, data: DemoData = {}) => {
  mixpanel.track(event, data);
};
