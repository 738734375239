import {
  ChartBarSquareIcon,
  ChartPieIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import VerticalModalTemplate from '../../modals/templates/verticalModalTemplate/verticalModalTemplate';
import { useState } from 'react';
import { RadialStat } from '../radialStat/radialStat';
import { trackHomePageData } from '../../../pages/Homepage/analytics';

export const GradientStatBox = (props: {}) => {
  let [openStatChart, setOpenStatChart] = useState(false);
  return (
    <>
      <VerticalModalTemplate
        isOpen={openStatChart}
        theme="info"
        closeModal={() => {
          setOpenStatChart(false);
        }}
      >
        <RadialStat />
      </VerticalModalTemplate>
      <div className="grid md:grid-cols-10 md:gap-x-4 gap-x-0 gap-y-4">
        <div className="md:hidden block">
          <RadialStat />
        </div>
        <div
          className="md:flex hidden bg-gradient-to-r from-slate-900 to-slate-700 rounded-md px-8 py-6 col-span-1 cursor-pointer flex justify-center items-center hover:from-slate-700 hover:to-slate-500"
          onClick={() => {
            setOpenStatChart(true);
            trackHomePageData('Clicked modal graph home page');
          }}
        >
          <ChartBarSquareIcon className="w-20 h-20 text-white" />
        </div>
        <div className="bg-gradient-to-r from-slate-900 to-slate-700 rounded-md px-8 py-6 col-span-3">
          <div className="flex justify-between items-center">
            <div className="bg-gradient-to-r from-amber-200 to-yellow-500 bg-clip-text text-transparent font-bold text-2xl mb-4 flex items-center">
              <div className="bg-yellow-200 rounded-full w-4 h-4 p-4  text-sm border-2 border-yellow-500 text-yellow-500 flex items-center justify-center font-bold mr-2">
                B1
              </div>
              Grammar
            </div>
          </div>
          <div className="relative h-5 rounded-md overflow-hidden bg-gray-300 w-full">
            <div
              className="absolute top-0 bottom-0 left-0 rounded-md bg-gradient-to-r from-amber-200 to-yellow-500"
              style={{ width: '54%' }}
            ></div>
          </div>
          <div className="flex justify-between items-center mt-2">
            <div className="text-white text-sm">Reach 67 for B2</div>
            <div className="font-semibold text-sm text-yellow-500">
              54 score
            </div>
          </div>
        </div>
        <div className="bg-gradient-to-r from-slate-900 to-slate-700 rounded-md px-8 py-6 col-span-3">
          <div className="flex justify-between items-center">
            <div className="bg-gradient-to-r from-cyan-500 to-blue-500 bg-clip-text text-transparent font-bold text-2xl mb-4 flex items-center">
              <div className="bg-blue-200 border-blue-500 text-blue-500 rounded-full w-4 h-4 p-4 text-sm border-2 flex items-center justify-center font-bold mr-2">
                B2
              </div>
              Vocabulary
            </div>
          </div>
          <div className="relative h-5 rounded-md overflow-hidden bg-gray-300 w-full">
            <div
              className="absolute top-0 bottom-0 left-0 rounded-md bg-gradient-to-r from-cyan-500 to-blue-500"
              style={{ width: '71%' }}
            ></div>
          </div>
          <div className="flex justify-between items-center mt-2">
            <div className="text-white text-sm">Reach 84 for C1</div>
            <div className="font-semibold text-sm text-blue-500">71 score</div>
          </div>
        </div>
        <div className="bg-gradient-to-r from-slate-900 to-slate-700 rounded-md px-8 py-6 col-span-3">
          <div>
            <div className="bg-gradient-to-r from-amber-500 to-pink-500 bg-clip-text text-transparent font-bold text-2xl mb-4 flex items-center">
              <div className="bg-pink-200 border-pink-500 text-pink-500 rounded-full w-4 h-4 p-4 text-sm border-2  flex items-center justify-center font-bold mr-2">
                A2
              </div>
              Pronunciation
              <span className="ml-5 h-5 inline-flex text-white font-bold items-center rounded-md bg-purple-100 px-1.5 py-0.5 text-xs bg-gradient-to-r from-indigo-400 to-cyan-400 ml-auto">
                <SparklesIcon className="w-4 h-4 mr-1" /> Beta
              </span>
            </div>
          </div>
          <div className="relative h-5 rounded-md overflow-hidden bg-gray-300 w-full">
            <div
              className="absolute top-0 bottom-0 left-0 rounded-md bg-gradient-to-r from-amber-500 to-pink-500"
              style={{ width: '45%' }}
            ></div>
          </div>
          <div className="flex justify-between items-center mt-2">
            <div className="text-white text-sm">Reach 50 for B1</div>
            <div className="font-semibold text-sm text-pink-500">45 score</div>
          </div>
        </div>
      </div>
    </>
  );
};
