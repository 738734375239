import { useEffect, useState } from 'react';
import { cx } from 'classix';
import moment from 'moment';
import { BookingData } from '../../../firebase/subscription/subscription';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { collection, doc } from 'firebase/firestore';
import { initializeFirebase } from '../../../firebase/configValues';
import { trackBookingAnalytics } from '../../Booking/analytics';
import {
  BookingCalendarDayObject,
  BookingCalendarWithButton,
} from './calendar/calendar';
import { UpcomingClassContainer } from './upcomingClassContainer/upcomingClassContainer';
import bookingSVG from '../../../images/undraw/undraw_booked.svg';
import { UpcomingClassContainerLoader } from './upcomingClassContainer/loader';
import { CancelBookingConfirmationModal } from '../../../components/modals/cancelBookingModals/cancelBookingConfirmationModal/cancelBookingConfirmationModal';
import { CancelBookingConfirmedModal } from '../../../components/modals/cancelBookingModals/cancelBookingConfirmedModal/cancelBookingConfirmedModal';
import BookingModalStacked from './bookingModal/bookingModalStacked';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import { SpreadBookingModal } from '../../../components/booking/modals/spreadBookingModal';
import { UserData } from '../../../types/user';
import { ConfirmBookingModal } from '../../../components/modals/confirmBookingModal/confirmBookingModal';

const { firestore } = initializeFirebase();

const useUserAvailableCredit = (userID: string) => {
  const [data, loading, error] = useDocumentData(
    doc(firestore, `users/${userID}`),
  );

  if (error) throw error;
  return {
    availableClassesCredit: data?.availableClasses,
    availableClassesLoading: loading,
    availableClassesError: error,
  };
};

export default function BookingCalendar(props: {
  userID: string;
  subProductID: string;
  bookings: Array<BookingData>;
  takenClasses: Array<BookingData>;
  bookingsLoading: boolean;
  unlimited?: boolean;
  onAction?: Function;
  onActionComplete?: Function;
}) {
  const currentDateMomentLocal = moment(new Date().valueOf()).local();

  const {
    userID,
    bookings,
    bookingsLoading,
    takenClasses,
    onAction,
    subProductID,
    unlimited,
    onActionComplete,
  } = props;
  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  console.log(takenClasses);
  const [selectedDay, setSelectedDay] = useState<string>(
    currentDateMomentLocal.format('YYYY-MM-DD'),
  );
  const { availableClassesCredit } = useUserAvailableCredit(userID);
  const [cancelBooking, setCancelBooking] = useState<undefined | BookingData>();
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState(false);
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const [openSpreadModal, setOpenSpreadModal] = useState(false);
  const [openConfirmBookingModal, setOpenConfirmBookingModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (availableClassesCredit !== undefined)
      dispatch({
        type: 'UPDATE_USER_CREDIT',
        availableClasses: availableClassesCredit,
      });
  }, [availableClassesCredit]);

  return (
    <div className="@container rounded-lg bg-white shadow w-full py-6 px-4">
      <ConfirmBookingModal
        userID={userID}
        isOpen={openConfirmBookingModal}
        onConfirm={() => {
          setOpenConfirmBookingModal(false);
        }}
        onClose={() => {
          setOpenConfirmBookingModal(false);
        }}
        unlimited={unlimited}
      />
      <SpreadBookingModal
        onOpen={() => {}}
        onClose={() => {
          setOpenSpreadModal(false);
        }}
        open={openSpreadModal}
        availableClassesNumber={availableClassesCredit}
        userID={userID}
        bookedClassSessionIds={bookings?.map((b) => b.classSessionID ?? '')}
        bookings={bookings}
        onBooking={() => {
          setOpenSpreadModal(false);
          setOpenConfirmBookingModal(true);
          trackBookingAnalytics('Booked classes on expanded booking calendar');
          onActionComplete?.();
        }}
        unlimited={
          userData?.product?.metadata?.credits === 'inf' &&
          !userData?.servicePaused
        }
      />
      <BookingModalStacked
        userBookings={bookings}
        userTakenClasses={takenClasses}
        chosenDate={selectedDay}
        isOpen={openBookingModal}
        userID={userID}
        availableClassesNumber={availableClassesCredit}
        onClose={() => {
          trackBookingAnalytics('Closed booking class modal', {
            selectedDate: selectedDay,
            userID: userID,
          });
          setOpenBookingModal(false);
        }}
        onSubmit={() => {
          onAction?.();
        }}
        onSuccess={() => {
          setOpenConfirmBookingModal(true);
          onActionComplete?.();
        }}
      />
      <CancelBookingConfirmedModal
        userID={userID}
        isOpen={openConfirmCancelModal}
        onConfirm={() => {
          setOpenConfirmCancelModal(false);
          setCancelBooking(undefined);
        }}
        unlimited={unlimited}
        onClose={() => {
          setOpenConfirmCancelModal(false);
          setCancelBooking(undefined);
        }}
      />
      <CancelBookingConfirmationModal
        cancelBooking={cancelBooking}
        onNotTodayClick={() => {
          trackBookingAnalytics(
            'Clicked not today on booking cancelation modal',
            {
              userID: userID,
              bookingTitle: cancelBooking?.title,
              bookingStartMili: cancelBooking?.startMili,
            },
          );
          setCancelBooking(undefined);
        }}
        unlimited={unlimited}
        onCloseModal={() => {
          trackBookingAnalytics('Closed booking cancelation modal', {
            userID: userID,
            bookingTitle: cancelBooking?.title,
            bookingStartMili: cancelBooking?.startMili,
          });
          setCancelBooking(undefined);
        }}
        onCancelCompleted={() => {
          // setCancelBooking(undefined)
          setOpenConfirmCancelModal(true);
          onActionComplete?.();
        }}
        onCancelClick={() => {
          onAction?.();
          if (cancelBooking?.id) {
            trackBookingAnalytics(
              'Clicked cancel on booking cancel confirmation modal',
              {
                userID: userID,
                bookingTitle: cancelBooking.title,
                bookingStartMili: cancelBooking.startMili,
              },
            );
          }
        }}
      />
      <div className="flex items-center justify-between">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Upcoming classes
        </h2>
        <div
          className="relative"
          onClick={() => {
            setOpenSpreadModal(true);
            trackBookingAnalytics('Clicked expand booking calendar');
          }}
        >
          <span className="ml-1 inline-flex items-center rounded-md bg-purple-100 px-1.5 py-0.5 text-[0.60rem] font-medium text-purple-700 absolute -left-12 -top-4">
            <SparklesIcon className="w-3 h-3" /> New!
          </span>
          <ArrowsPointingOutIcon className="w-5 h-5 text-gray-500 cursor-pointer hover:text-blue-immigo" />
        </div>
      </div>

      <div className="@2xl:grid @2xl:grid-cols-12 @2xl:gap-x-16">
        <BookingCalendarWithButton
          availableClassesCredit={availableClassesCredit}
          futureClassDates={bookings?.map((booking) => {
            return moment(booking?.startMili).format('YYYY-MM-DD');
          })}
          onBookClick={() => {
            trackBookingAnalytics('Clicked book a class at booking calendar', {
              userID: userID,
              selectedDate: selectedDay,
            });
            setOpenBookingModal(true);
          }}
          onSelectedDay={(day: BookingCalendarDayObject) => {
            trackBookingAnalytics(
              'Clicked a date on upcoming classes calendar',
              {
                userID: userID,
                selectedDate: day.date,
              },
            );
            setSelectedDay(day.date);
          }}
          selectedDay={selectedDay}
        />
        <div className="@2xl:grid @2xl:grid-cols-12 @2xl:gap-x-16">
          <ol
            className={cx(
              'relative mt-4 divide-y divide-gray-100 text-sm leading-6 @5xl:min-w-[500px] @7xl:min-w-[600px] @2xl:col-span-7 @2xl:col-span-8 max-h-[500px] overflow-x-visible px-2',
              bookings.length > 2 ? 'overflow-y-scroll' : 'overflow-y-visible',
            )}
          >
            {bookings?.length > 0 ? (
              bookings.map((booking: BookingData) => (
                <UpcomingClassContainer
                  key={booking.id + '-' + booking.startMili}
                  {...booking}
                  onTrashClick={() => {
                    trackBookingAnalytics(
                      'Clicked trash icon on booking calendar',
                      {
                        userID: userID,
                        bookingTitle: booking.title,
                        bookingStartMili: booking.startMili,
                      },
                    );
                    setCancelBooking(booking);
                  }}
                  onClassLinkClick={() => {
                    trackBookingAnalytics(
                      'Clicked class link in the homepage booking modal',
                      {
                        userID: userID,
                        bookingTitle: booking.title,
                        bookingStartMili: booking.startMili,
                      },
                    );
                  }}
                />
              ))
            ) : !bookingsLoading ? (
              <div className="flex flex-col justify-center items-center py-5">
                <img
                  className="mt-10 md:mt-0 w-72"
                  src={bookingSVG}
                  alt={'booking-photo'}
                />
                <div className="text-2xl mt-6">No upcoming classes</div>
                <div className="text-gray-500 mt-4">
                  Your upcoming classes will show up here upon booking.
                </div>
              </div>
            ) : (
              <div>
                <UpcomingClassContainerLoader />
              </div>
            )}
          </ol>
        </div>
      </div>
      <div className="text-gray-500 text-sm mt-4">
        Note: Make sure to cancel at least 24 hours before the class starts to
        get your class credit back to reschedule.
      </div>
    </div>
  );
}
