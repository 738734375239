import mixpanel from '../../../analytics/mixpanel';

type Events =
  | 'Teacher input report notifier modal opened'
  | 'Teacher input report notifier modal closed';

type teacherInputReportNotifierData = {
  reportNumber?: number;
  lastOpened?: number;
  message?: string;
  last5Inputs?: Array<any>;
};

export const trackTeacherInputReportNotifier = (
  event: Events,
  data?: teacherInputReportNotifierData,
) => {
  mixpanel.track(event, data);
};
