import { useEffect, useState } from 'react';
import {
  getClass,
  getClassSession,
  getUserBookingClassSession,
  BookingData,
} from '../../firebase/subscription/subscription';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import { UserData } from '../../types/user';
import moment from 'moment';
import {
  addBookingLog,
  checkBookingAttended,
} from '../../firebase/subscription/bookings/bookings';
import { ClassSetUpContainer } from '../../features/ClassLinkRedirect/setup/classSetUpContainer';
import LoginRegisterBox from '../../components/forms/loginregister';
import { initializeFirebase } from '../../firebase/configValues';
import { compressRLE } from '../../util/string/hash';

const { auth } = initializeFirebase();

export const ClassLinkRedirect = () => {
  const [timeLeftToStartMoment, setTimeLeftToStart] = useState<
    moment.Duration | undefined
  >();
  const [meetingLink, setMeetingLink] = useState<undefined | string>();
  const [classOpen, setClassOpen] = useState(false);
  const [booking, setBooking] = useState<BookingData | undefined>();
  const [skipMicAccess, setSkipMicAccess] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get('page');

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  const classSessionID = window.location.pathname.split('/')[2];
  const classEntranceLimit =
    userData?.email === 'info@immigo.io' ? 1000000 * 1000000 : 1000 * 60 * 10; // Can only attend 10 minutes before the class starts

  async function log(l: string) {
    if (booking?.id) await addBookingLog(booking?.id, l, 'class_link_redirect');
  }

  async function getClassLink() {
    if (classSessionID) {
      try {
        const session: BookingData = (await getClassSession(
          classSessionID,
        )) as BookingData;
        const timeLeftToStart = session.startMili - new Date().valueOf(); // session.startMili - new Date().valueOf();

        if (session?.classID) {
          const booking = await getUserBookingClassSession(
            userData.id,
            classSessionID,
          );
          setBooking(booking);
          const classData = await getClass(session.classID);
          if (!classData?.recurringMeetingUrl)
            await log(
              `Error: Class Link Redirect: recurringMeetingUrl is missing`,
            );
          setMeetingLink(classData?.recurringMeetingUrl);
          if (timeLeftToStart <= classEntranceLimit) {
            if (classData?.recurringMeetingUrl) {
              if (booking?.id) await checkBookingAttended(booking.id); // Check booking attended
              // window.location.href = classData?.recurringMeetingUrl; // Redirect
              setClassOpen(true);
            }
            console.log(timeLeftToStartMoment);
          } else if (timeLeftToStart > classEntranceLimit) {
            setInterval(() => {
              setTimeLeftToStart(
                moment.duration(
                  session.startMili - new Date().valueOf() - classEntranceLimit,
                ),
              );
            }, 1000);
          }
        } else {
          await log(`Error: Class Link Redirect: session classID is missing`);
        }
      } catch (err) {
        await log(`Error: Class Link Redirect: ${err}`);
      }
    } else {
      await log(`Error: Class Link Redirect: classSessionID missing`);
      return false;
    }
  }

  useEffect(() => {
    if (userData) getClassLink();
  }, [userData]);

  return (
    <div className="flex justify-center items-center w-screen flex-col">
      {auth.currentUser ? (
        !timeLeftToStartMoment && !classOpen ? (
          <>
            <ReactLoading
              className="mb-20 mx-auto"
              type={'bubbles'}
              color={'#009eff'}
              height={300}
              width={300}
            />
            <div className="text-xl text-blue-immigo">Give us a second :)</div>
          </>
        ) : classOpen ? (
          <ClassSetUpContainer
            classSessionID={classSessionID}
            booking={booking}
            skipMicAccess={skipMicAccess}
            onSetUpFinish={() => {
              if (meetingLink)
                window.open(
                  `${meetingLink}?uname=${userData?.name}-${userData?.id}`,
                  '_blank',
                );
            }}
            corporate={userData?.corporate}
          />
        ) : timeLeftToStartMoment ? (
          <div className="text-xl text-blue-immigo text-center">
            <div className="text-2xl font-bold my-6">
              You can enter the classroom 10 minutes before the class starts.
            </div>
            {timeLeftToStartMoment.asMilliseconds() <= 0
              ? `Refresh to enter the room.`
              : `Come back in 
            ${
              timeLeftToStartMoment.days()
                ? timeLeftToStartMoment.days() + ' days '
                : ''
            }
            ${
              timeLeftToStartMoment.hours()
                ? timeLeftToStartMoment.hours() + ' hours'
                : ''
            }
            ${
              timeLeftToStartMoment.minutes()
                ? timeLeftToStartMoment.minutes() + ' minutes'
                : ''
            } 
            ${
              timeLeftToStartMoment.seconds()
                ? timeLeftToStartMoment.seconds() + ' seconds'
                : ''
            }!`}
          </div>
        ) : null
      ) : (
        <LoginRegisterBox
          onLoginSuccess={() => {
            window.location.reload();
          }}
        />
      )}
      {page === 'mic' ? (
        <div className="mt-24 text-sm text-gray-500">
          Still loading? Click{' '}
          <a
            className="text-blue-immigo"
            target="_blank"
            href={meetingLink}
            onClick={() => {
              console.log('backup');
              setSkipMicAccess(true);
            }}
            rel="noreferrer"
          >
            here
          </a>{' '}
          to access your class.
        </div>
      ) : (
        <div className="mt-24 text-sm text-gray-500">
          <span>
            Need help? Contact us{' '}
            <a
              className="text-blue-immigo"
              target="_blank"
              href="https://wa.me/+18299858482"
              rel="noreferrer"
            >
              here
            </a>
            !
          </span>
          <span>
            Need to get back to your class? Click{' '}
            <a
              className="text-blue-immigo"
              target="_blank"
              href={meetingLink}
              rel="noreferrer"
            >
              here
            </a>
            !
          </span>
        </div>
      )}
    </div>
  );
};
