import axios from 'axios';
import { UserPreviewActivityType } from '../firebase/subscription/subscription';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/database'
    : 'http://localhost:8080/database'; // Dev env

export async function updateClassSessionUserWorkOn(
  sessionId: string,
  uid: string,
  workOn: Array<string>,
  raterId: string,
) {
  console.log(workOn);
  const data = await axios.post(`${endPoint}/updateClassSessionUserWorkOn`, {
    sessionId,
    uid,
    workOn,
    raterId,
  });
  return data?.data;
}

export async function updateClassSessionUserImproved(
  sessionId: string,
  uid: string,
  improved: Array<string>,
  raterId: string,
) {
  console.log(improved);
  const data = await axios.post(`${endPoint}/updateClassSessionUserImproved`, {
    sessionId,
    uid,
    improved,
    raterId,
  });
  return data?.data;
}

export async function getClassSessionUserTeacherInput(
  sessionId: string,
  uid: string,
) {
  const res = await axios.get(
    `${endPoint}/getClassSessionUserTeacherInput/${sessionId}/${uid}`,
  );
  return res?.data?.data;
}
