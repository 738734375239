import cx from 'classix';

import { getNextCefrLevel } from '../../../util/cefr';
import { PrimaryButton } from '../../../components/buttons/primary';
import { useEffect } from 'react';
import { trackPricingPageEvents } from '../../../features/PricingPage/analytics';
import {
  stringsPricingPage,
  stringsSchedulePage,
} from '../../../util/localization';
import { CEFRLevel } from '../../../types/level';

const cefrToVideoUrl: any = {
  A1: 'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/level_samples%2Fa1_clip.mov?alt=media&token=fafbc1d2-9ddf-4ae2-af1c-b63452590e1d',
  A2: 'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/level_samples%2Fa2_clip.mov?alt=media&token=2b3506f7-0b2c-407d-8b5c-f41d7e6f0026',
  B1: 'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/level_samples%2Fb1_clip.mov?alt=media&token=46317c2c-a4ad-4967-9cd2-b3e762d6e7f7',
  B2: 'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/level_samples%2Fb2_clip.mov?alt=media&token=90262f4d-7d20-46ac-9350-3fb3bd8a4e1a',
  C1: 'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/level_samples%2Fc1_clip_v2.mov?alt=media&token=5e724d7e-5b5f-4b47-84e8-52e448680390',
  C2: 'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/level_samples%2Fc2_clip.mp3?alt=media&token=918fa252-5f08-4f42-a709-d4de454fb688',
};

const ProgressBox = (props: {
  level: string;
  status: string;
  emoji: string;
  time: string;
  videoUrl?: string;
  audioOnly?: boolean;
  timeStyle?: string;
  containerStyle?: string;
}) => {
  const {
    level,
    status,
    emoji,
    time,
    timeStyle,
    containerStyle,
    videoUrl,
    audioOnly,
  } = props;
  const videoId = `${level}-video`;

  useEffect(() => {
    const videoRef: HTMLAudioElement = document.getElementById(
      videoId,
    ) as HTMLAudioElement;
    console.log(videoRef);
    if (videoRef) {
      videoRef.addEventListener('playing', () => {
        console.log(`playing ${videoId} video`);
        trackPricingPageEvents(
          'Played CEFR video in vision page in pricing page',
          {
            cefrLevel: level,
          },
        );
      });
    }
  }, []);

  return (
    <div
      className={cx(
        'my-4 p-4 rounded-md my-4 text-center mx-4 max-w-[300px]',
        containerStyle,
      )}
    >
      <div className={cx('rounded-md font-bold py-1 px-2 mb-6', timeStyle)}>
        {time}
      </div>
      {/* <div className="text-8xl mb-2">{emoji}</div> */}
      <div>
        <video id={videoId} className="my-8" controls src={videoUrl} />
      </div>
      <div className={cx('font-semibold rounded-md', timeStyle)}>{level}</div>
      <div className="mt-4 text-gray-600  dark:text-white">{status}</div>
    </div>
  );
};

export const PersonalProgress = (props: { onNextClick: Function }) => {
  const { onNextClick } = props;

  const cefrLevel: CEFRLevel = window.sessionStorage.getItem(
    'cefrLevel',
  ) as CEFRLevel;
  const nextLevel = getNextCefrLevel(cefrLevel);
  const nextNextLevel = nextLevel ? getNextCefrLevel(nextLevel) : undefined;
  const motivation = window.sessionStorage.getItem('motivation');
  const basedCountry = window.sessionStorage.getItem('basedCountry');
  const nationality = window.sessionStorage.getItem('nationality');

  useEffect(() => {
    trackPricingPageEvents('Entered vision page on pricing page', {
      cefrLevel: cefrLevel,
      motivation: motivation ?? undefined,
      basedCountry: basedCountry ?? undefined,
      nationality: nationality ?? undefined,
    });
  }, []);

  function generateProgressBox() {
    const motivationToProgressMap: any = {
      'Advance my career': [
        stringsPricingPage.nervousDuringInterviews,
        stringsPricingPage.feelPreparedForInterviews,
        stringsPricingPage.passedMyInterviewAndPromoted,
      ],
      'Expand my knowledge': [
        stringsPricingPage.useLimitedEnglishWords,
        stringsPricingPage.thinkAndExpressInEnglish,
        stringsPricingPage.comfortableUsingExpressionsUsedByNative,
      ],
      Other: [
        stringsPricingPage.useLimitedEnglishWords,
        stringsPricingPage.thinkAndExpressInEnglish,
        stringsPricingPage.comfortableUsingExpressionsUsedByNative,
      ],
      'Confidence in social settings': [
        stringsPricingPage.troubleExpressingInSocial,
        stringsPricingPage.canThinkAndExpressMyself,
        stringsPricingPage.comfortableJokeInEnglish,
      ],
    };
    const twoLevels = !nextLevel || !nextNextLevel; // Only has 2 steps (now and next) as opposed to 3 normally
    console.log(nextLevel);

    const progressArray = [
      <ProgressBox
        key={'first-box'}
        emoji="😿"
        status={motivationToProgressMap[motivation ?? ''][0]}
        videoUrl={cefrToVideoUrl[cefrLevel]}
        level={cefrLevel}
        time={stringsPricingPage.now}
        timeStyle="bg-gray-300 text-gray-600"
        containerStyle="border border-gray-200"
      />,
    ];

    progressArray.push(
      <ProgressBox
        key={'second-box'}
        emoji={twoLevels ? '😸' : '🐱'}
        status={motivationToProgressMap[motivation ?? ''][twoLevels ? 2 : 1]}
        level={nextLevel ?? stringsPricingPage.nativeLevel}
        videoUrl={cefrToVideoUrl[nextLevel ?? stringsPricingPage.nativeLevel]}
        time={`6 ${stringsPricingPage.months}`}
        timeStyle={
          !twoLevels
            ? 'bg-yellow-400 text-yellow-700'
            : 'bg-orange-500 text-yellow-200'
        }
        containerStyle={
          !twoLevels
            ? 'border border-yellow-400'
            : 'border-2 border-orange-500 shadow-xl shadow-orange-500/50'
        }
      />,
    );

    if (!twoLevels) {
      progressArray.push(
        <ProgressBox
          emoji={'😸'}
          key={'third-box'}
          status={motivationToProgressMap[motivation ?? ''][2]}
          level={nextNextLevel}
          videoUrl={cefrToVideoUrl[nextNextLevel]}
          time={`1 ${stringsPricingPage.year}`}
          timeStyle={'bg-orange-500 text-yellow-200'}
          containerStyle="border-2 border-orange-500 shadow-xl shadow-orange-500/50"
        />,
      );
    }

    return progressArray;
  }

  return (
    <div>
      <div className="mx-auto max-w-4xl text-center">
        <p className="mt-2 md:text-3xl text-xl font-bold tracking-tight text-gray-900 dark:text-white">
          {stringsPricingPage.envisionYourSelf}
        </p>
      </div>
      <p className="mx-auto md:mt-6 max-w-2xl text-center md:text-lg text-md leading-8 text-gray-600 dark:text-white">
        {stringsPricingPage.basedOnWhatYouToldJourney}{' '}
        <span className="md:hidden block">
          {stringsPricingPage.scrollDownToSee}
        </span>
      </p>
      <div className="mt-4 text-xs text-gray-500 md:text-left text-center dark:text-white">
        * {stringsPricingPage.basedOnStudentProgress}
      </div>
      {cefrLevel && motivation ? (
        <div className="flex md:flex-row flex-col items-center justify-center mb-2">
          {generateProgressBox()}
        </div>
      ) : null}
      <PrimaryButton
        text={stringsPricingPage.amReadyToLearn}
        onClick={() => {
          trackPricingPageEvents(
            'Click continue on vision page on pricing page',
            {
              cefrLevel: cefrLevel,
              motivation: motivation ?? undefined,
              basedCountry: basedCountry ?? undefined,
              nationality: nationality ?? undefined,
            },
          );
          onNextClick();
        }}
      />
    </div>
  );
};
