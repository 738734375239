import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/popUpStatus'
    : 'http://localhost:8080/popUpStatus'; // Dev env

export async function getUserPopUpStatus(uid: string, popupId: string) {
  const res = await axios.get(`${endPoint}/${uid}/${popupId}`);
  return res?.data?.data;
}

export async function recordPopUpOpen(
  uid: string,
  popupId: string,
  metadata?: any,
) {
  const res = await axios.post(`${endPoint}/record/open/${uid}/${popupId}`, {
    uid,
    popupId,
    metadata,
  });
  return res;
}

export async function recordPopUpClose(uid: string, popupId: string) {
  const res = await axios.post(`${endPoint}/record/close/${uid}/${popupId}`, {
    uid,
    popupId,
  });
  return res;
}
