import {
  DocumentData,
  DocumentReference,
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import {
  Correction,
  CorrectionDocument,
  CorrectionRatingType,
} from '../../types/feedback';
import { initializeFirebase } from '../configValues';
import {
  getBookingDocument,
  updateBookingDetail,
} from '../subscription/bookings/bookings';
import { SpeechMaticTranscriptResult } from '../../types/speechmatic';
import {
  parseFormattedTranscriptString,
  returnFormattedStringsGivenResultsArray,
  returnFormattedStringsGivenResultsArrayWithMultipleSpeakers,
} from '../../util/speechmaticDataHandler';
import { getCorrectionAI } from '../../api';

const { firestore: db } = initializeFirebase();

export const createCorrectionDocument = async (
  bookerID: string,
  bookingID: string,
  needChecked?: boolean,
): Promise<CorrectionDocument> => {
  const correctionData: CorrectionDocument = {
    corrections: [],
    transcript: '',
    bookingID,
    bookerID,
    createdAt: new Date().valueOf(),
  };

  if (needChecked) correctionData.needChecked = true;

  const docRef = await addDoc(collection(db, 'corrections'), correctionData);
  const id = docRef.id;

  correctionData.id = id;

  await updateBookingDetail(bookingID, {
    correctionID: id,
  }); // Update relevant correction data doc ID

  return correctionData;
};

// Add another correction to the document
export const addCorrection = async (
  correctionID: string,
  correction: Correction,
) => {
  const correctionRef = await doc(db, 'corrections', correctionID);

  const correctionString = JSON.stringify(correction);
  await updateDoc(correctionRef, {
    corrections: arrayUnion(correctionString),
  });
};

// Add transcript to the document
export const addTranscript = async (
  correctionID: string,
  transcript: string,
) => {
  const correctionDoc = await getCorrectionDocument(correctionID);
  const correctionRef = await doc(db, 'corrections', correctionID);
  const originalTranscript = correctionDoc?.transcript || '';
  await updateDoc(correctionRef, {
    transcript: `${originalTranscript.trim()} ${transcript}`, // There's always going to be a space in between.
  });
};

export const updateCorrectionsWithFormattedTranscriptArray = async (
  correctionID: string,
  newFormattedTranscriptArray: Array<string>,
  existingCorrections: Array<Correction>,
) => {
  await Promise.all(
    newFormattedTranscriptArray.map(async (s: string) => {
      const { content, startSeconds, endSeconds, speaker } =
        parseFormattedTranscriptString(s);
      console.log('adding correction');
      console.log(existingCorrections);

      // This was made to fix duplicate correction being made.
      if (
        content &&
        !existingCorrections?.map((c) => c?.original)?.includes(content)
      ) {
        const res = await getCorrectionAI(content);
        const { correction, mistake, explanation } = res.data ?? {};
        console.log(correction);
        if (
          !correction ||
          !mistake ||
          !explanation ||
          mistake.includes('No mistake') ||
          correction?.toLowerCase().trim() === content?.toLowerCase().trim()
        ) {
          return;
        } else {
          const correctionPair: Correction = {
            original: content,
            fixed: correction,
            explanation,
            mistake,
            startSeconds,
            endSeconds,
            correctedAt: new Date().valueOf(),
            speakerTag: speaker,
          };
          await addCorrection(correctionID, correctionPair);
          return correctionPair;
        }
      }
    }),
  );
};

// Add formatted transcript to the document
export const updateFormattedTranscriptsAndAddCorrections = async (
  correctionID: string,
  allResults: Array<SpeechMaticTranscriptResult>,
  formattedResults?: Array<string>,
) => {
  const correctionRef = await doc(db, 'corrections', correctionID);
  const formattedTranscriptArray: Array<string> =
    formattedResults ??
    returnFormattedStringsGivenResultsArrayWithMultipleSpeakers(allResults);
  const correctionDoc = await getCorrectionDocument(correctionID);

  const formattedTranscriptArrayOld =
    correctionDoc?.formattedTranscriptArray ?? [];

  console.log(
    formattedTranscriptArrayOld.map((s: string) => {
      return s.split('-').splice(1).join('-'); // Get rid of the timestamp to standardize
    }),
  );

  let newSentences = formattedTranscriptArray.filter(
    (x) =>
      !formattedTranscriptArrayOld
        .map((s: string) => {
          return s.split('-').splice(1).join('-'); // Get rid of the timestamp to standardize
        })
        .includes(x), // This would break if somehow timestamp & content are exactly the same
  );
  const newSentencesWithTimeStamp = newSentences.map((s: string) => {
    return `${new Date().valueOf()}-${s}`; // Add current time this sentence is being added
  });
  console.log(newSentences);
  console.log(newSentencesWithTimeStamp);
  const newFormattedTranscript = [
    ...formattedTranscriptArrayOld,
    ...newSentencesWithTimeStamp,
  ];

  await updateDoc(correctionRef, {
    formattedTranscriptArray: newFormattedTranscript,
  });
  if (!correctionDoc?.corrections || correctionDoc?.corrections.length < 30) {
    // Check duplicate
    if (correctionDoc && correctionDoc?.corrections.length > 2) {
      const added = new Set();
      const newArray: Array<string> = [];
      correctionDoc.corrections.forEach((correction: Partial<Correction>) => {
        console.log(correction);
        if (!added.has(correction.original)) {
          added.add(correction.original);
          newArray.push(JSON.stringify(correction));
        }
      });

      await updateDoc(correctionRef, {
        corrections: newArray,
      });
    }

    await updateCorrectionsWithFormattedTranscriptArray(
      correctionID,
      newSentencesWithTimeStamp,
      correctionDoc?.corrections ?? [],
    ); // Update Corrections
  }
};

export const getCorrectionDocument = async (correctionID: string) => {
  const correctionRef = await doc(db, 'corrections', correctionID);
  const correctionDoc = await getDoc(correctionRef);
  const correctionData = correctionDoc.data();

  if (correctionData?.corrections) {
    const parsed = correctionData?.corrections?.map(
      (correctionString: string) => {
        return JSON.parse(correctionString);
      },
    );
    correctionData.corrections = parsed;
  }

  if (correctionData) correctionData.id = correctionID;

  return correctionData;
};

export const getCorrectionsFromBooking = async (bookingID: string) => {
  const bookingData = await getBookingDocument(bookingID);
  const correctionID = bookingData?.correctionID;
  if (!correctionID) return undefined;

  const correctionDoc = await getCorrectionDocument(correctionID);
  if (correctionDoc) correctionDoc.id = correctionID;
  console.log(correctionDoc);
  return correctionDoc;
};

export const updateCorrectionDoc = async (
  correctionID: string,
  updateObj: Partial<CorrectionDocument>,
) => {
  const correctionRef = await doc(db, 'corrections', correctionID);
  const res = await updateDoc(correctionRef, updateObj);
  return res;
};

export const updateCorrectionRating = async (
  correctionID: string,
  rating: CorrectionRatingType,
  correction: Correction,
) => {
  const correctionDoc = ((await getCorrectionDocument(correctionID)) ??
    {}) as CorrectionDocument;
  const correctionArray = correctionDoc.corrections;
  correctionArray.map((c: Correction, i: number) => {
    const { original, fixed, explanation } = correction;
    if (
      c.original === original &&
      c.fixed === fixed &&
      c.explanation === explanation
    ) {
      correctionArray[i] = { ...correctionArray[i], rating: rating }; // update rating
    }
    //@ts-ignore
    correctionArray[i] = JSON.stringify(correctionArray[i]);
  });
  await updateCorrectionDoc(correctionID, {
    corrections: correctionArray,
  });
};
