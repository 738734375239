import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { cx } from 'classix';
import moment from 'moment';
import { getCalendarDatesGivenCurrentDate } from '../../../../util/dates';
import { SparkleButton } from '../../../../components/buttons/sparkle/sparkleButton';
import { BuyOneCreditButton } from '../../../../components/buttons/buyOneCredit/buyOneCredit';
import { useSelector } from 'react-redux';
import { UserData } from '../../../../types/user';
import {
  filterValidPasses,
  getValidPassExpiringSoonest,
} from '../../../../util/guestPass';
import { useEffect, useState } from 'react';

export interface BookingCalendarDayObject {
  date: string;
  isCurrentMonth?: boolean;
  isToday?: boolean;
  isSelected?: false;
}

export const BookingCalendarWithButton = (props: {
  onSelectedDay: Function;
  onBookClick: Function;
  availableClassesCredit: number;
  selectedDay: string;
  futureClassDates: Array<string>;
}) => {
  const {
    futureClassDates,
    onSelectedDay,
    selectedDay,
    onBookClick,
    availableClassesCredit,
  } = props;

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  const unlimited =
    userData?.product?.metadata?.credits === 'inf' && !userData?.servicePaused; // This needs replacing

  const noMoreCredits =
    (availableClassesCredit === undefined || availableClassesCredit <= 0) &&
    !unlimited; // User ran out of sub

  const [cannotBookMore, setCannotBookMore] = useState(noMoreCredits);

  const currentMonthString = moment(selectedDay).format('MMMM');

  const currentDateMomentLocal = moment(new Date().valueOf()).local();

  const days: Array<BookingCalendarDayObject> =
    getCalendarDatesGivenCurrentDate(moment(selectedDay)).map(
      (date: moment.Moment) => {
        return {
          date: date.format('YYYY-MM-DD'),
          isCurrentMonth: date.format('MMMM') === currentMonthString,
          isToday:
            date.format('YYYY-MM-DD') ===
            currentDateMomentLocal.format('YYYY-MM-DD'),
          isSelected: false,
        };
      },
    );

  useEffect(() => {
    if (userData?.guestPasses?.length) setCannotBookMore(false);
    if (availableClassesCredit) setCannotBookMore(false);
  }, [userData, availableClassesCredit]);

  return (
    <div className="mt-10 text-center @2xl:col-start-8 @2xl:col-end-13 @2xl:row-start-1 @2xl:mt-9 @2xl:col-start-8">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => {
            onSelectedDay({
              date: moment(selectedDay)
                .subtract(1, 'month')
                .format('YYYY-MM-DD'),
            });
          }}
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold">
          {moment(selectedDay).format('MMMM, YYYY')}
        </div>
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => {
            console.log(selectedDay);
            onSelectedDay({
              date: moment(selectedDay).add(1, 'month').format('YYYY-MM-DD'),
            });
          }}
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day, dayIdx) => (
          <button
            key={day.date}
            type="button"
            className={cx(
              'py-1.5 hover:bg-gray-100 focus:z-10',
              day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
              (day.date === selectedDay || day.isToday) && 'font-semibold',
              day.date === selectedDay && 'text-white',
              !(day.date === selectedDay) &&
                day.isCurrentMonth &&
                !day.isToday &&
                'text-gray-900',
              !(day.date === selectedDay) &&
                !day.isCurrentMonth &&
                !day.isToday &&
                'text-gray-400',
              day.isToday && !(day.date === selectedDay) && 'text-blue-immigo',
              dayIdx === 0 && 'rounded-tl-lg',
              dayIdx === 6 && 'rounded-tr-lg',
              dayIdx === days.length - 7 && 'rounded-bl-lg',
              dayIdx === days.length - 1 && 'rounded-br-lg',
            )}
            onClick={() => {
              onSelectedDay(day);
            }}
          >
            {day.date !== undefined ? (
              <time
                dateTime={day.date}
                className={cx(
                  'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                  day.date === selectedDay && day.isToday && 'bg-blue-immigo',
                  day.date === selectedDay && !day.isToday && 'bg-blue-immigo',
                  futureClassDates.includes(day.date) &&
                    'bg-blue-immigo-lighter text-blue-immigo bg-opacity-30 rounded-md',
                )}
              >
                {day.date.split('-') !== undefined
                  ? day.date.split('-').pop()?.replace(/^0/, '')
                  : ''}
              </time>
            ) : null}
          </button>
        ))}
      </div>
      <div className="text-start my-2">
        <div className="flex justify-between items-center">
          <div className="text-gray-500 text-md max-w-[300px]">
            <span className="text-blue-immigo font-semibold">
              {filterValidPasses(userData?.guestPasses ?? [])?.length}
            </span>{' '}
            <span className="text-sm">{` guest passes`}</span>
          </div>
          {filterValidPasses(userData?.guestPasses ?? [])?.length ? (
            <div className="text-gray-500 text-xs">
              {`Guest pass expiring ${moment(
                getValidPassExpiringSoonest(userData?.guestPasses ?? [])
                  ?.expires,
              ).format('MMMM DD, h:MMA')}`}
            </div>
          ) : null}
        </div>
        <div className="flex justify-between items-center">
          {availableClassesCredit !== undefined && !unlimited ? (
            <div className="text-gray-500 text-md">
              <span className="text-blue-immigo font-semibold">{`${
                availableClassesCredit > 0 ? availableClassesCredit : 0
              }`}</span>{' '}
              <span className="text-sm">{`credits`}</span>
            </div>
          ) : null}
          {userData?.nextCreditUpdate && !unlimited ? (
            <div className="text-gray-500 text-xs">
              {`Your credits renew on ${moment(
                userData?.nextCreditUpdate,
              ).format('MMMM DD, h:MMA')}`}
            </div>
          ) : null}
        </div>
      </div>
      <button
        onClick={() => {
          onBookClick();
        }}
        type="button"
        className={cx(
          'w-full rounded-md px-3 py-2 text-sm',
          'font-semibold text-white shadow r focus-visible:outline',
          'focus-visible:outline-2 focus-visible:outline-offset-2 ',
          'bg-blue-immigo hover:bg-blue-immigo-lighte focus-visible:outline-blue-immigo',
        )}
      >
        {`Book a class`}
      </button>
      {userData?.customerID &&
      userData?.id &&
      userData?.product?.metadata?.credits !== 'inf' ? (
        <div className="mt-1">
          <BuyOneCreditButton
            userId={userData.id}
            customerId={userData.customerID}
            email={userData.email}
            number={userData.phoneNumber?.replace('+', '')}
          />
        </div>
      ) : null}
    </div>
  );
};
