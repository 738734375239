import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com'
    : 'http://localhost:8080'; // Dev env

export async function getTranscriptInsight(text: string) {
  const data = await axios.post(endPoint + '/feedback/getTranscriptInsight', {
    text,
  });
  return data?.data;
}

export async function getAndCreateClassInsight(bookingID: string) {
  const data = await axios.post(
    endPoint + '/feedback/generateTranscriptInsightForBooking',
    {
      bookingID,
    },
  );
  return data?.data;
}

export async function getHowToAnswerQuestionUsingAnIdiomMultiple(
  idiom: string,
  questions: Array<string>,
) {
  const data = await axios.post(
    endPoint + '/feedback/getHowToAnswerQuestionUsingAnIdiomMultiple',
    {
      idiom,
      questions: `[${questions
        .map((q) => `"${q.replace(/['"]+/g, '')}"`)
        .join(',')}]`, // Remove quotes, join with comma to make a string array that's parsable
    },
  );
  return data?.data;
}

export async function generateYearlyInsight(year: number, uid: string) {
  const data = await axios.post(endPoint + '/feedback/generateYearlyInsight', {
    year,
    uid,
  });
  return data?.data?.data;
}

export async function getAllUserMistakes(uid: string, lowestMili?: number) {
  const res = await axios.get(
    `${endPoint}/feedback/getAllUserMistakes/${uid}/${
      lowestMili ? lowestMili : ''
    }`,
  );
  return res?.data?.data;
}

export async function getLastTeacherInput(uid: string, number: number) {
  const res = await axios.get(
    `${endPoint}/feedback/lastTeacherInputs/${uid}/${number}`,
  );
  return res?.data?.data;
}
