import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { initializeFirebase } from '../../../firebase/configValues';
import VerticalMessageModal from '../verticalMessageModal/verticalMessageModal';

const { firestore } = initializeFirebase();

const useUserAvailableCredit = (userID: string) => {
  const [data, loading, error] = useDocumentData(
    doc(firestore, `users/${userID}`),
  );

  return {
    availableClassesCredit: data?.availableClasses,
    availableClassesLoading: loading,
    availableClassesError: error,
  };
};

export const ConfirmBookingModal = (props: {
  userID: string;
  isOpen: boolean;
  onConfirm: Function;
  onClose: Function;
  unlimited?: boolean;
}) => {
  const { isOpen, onConfirm, onClose, userID, unlimited } = props;
  const { availableClassesCredit } = useUserAvailableCredit(userID);

  return (
    <VerticalMessageModal
      mainMessage="You successfully booked your class"
      subMessage={
        !unlimited
          ? `You have ${availableClassesCredit} more class${
              availableClassesCredit > 1 ? 'es' : ''
            } left to book`
          : ''
      }
      theme="success"
      buttonText="Continue"
      onButtonClick={onConfirm}
      onCloseModal={onClose}
      isOpen={isOpen}
    />
  );
};
