import { UserData } from '../../types/user';
import { countryCodeToName } from '../../util/forms';
import { capitalizeFirstLetter, countryCodeToEmoji } from '../../util/helper';
import { capitalizeEveryFirstLetter } from '../../util/standardization';
import emptyAvatar from '../../images/empty-avatar.webp';
import { cx } from 'classix';
import moment from 'moment';
import {
  TrashIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { FollowButton } from '../buttons/follow/followButton';
import DropDownFancy, {
  DropDownFancyOption,
} from '../forms/dropDownFancy/dropDownFancy';
import { PeopleBoxOptionSmall } from '../forms/peopleBoxOptionSmall/peopleBoxOptionSmall';
import {
  updateClassSessionUserImproved,
  updateClassSessionUserWorkOn,
} from '../../api/classSessions';
import { useState } from 'react';

const cefrOptions = [
  { name: 'A1', id: 'A1' },
  { name: 'A2', id: 'A2' },
  { name: 'B1', id: 'B1' },
  { name: 'B2', id: 'B2' },
  { name: 'C1', id: 'C1' },
  { name: 'C2', id: 'C2' },
];

const optionList: any = [
  {
    id: 'grammar',
    value: 'grammar',
    style: 'text-red-500 border-red-500 font-semibold hover:text-red-300',
    selectedStyle:
      'bg-red-500 text-white border-red-500 font-semibold hover:text-red-300',
  },
  {
    id: 'pronunciation',
    value: 'pronunciation',
    style:
      'text-orange-500 border-orange-500 font-semibold hover:text-orange-300',
    selectedStyle:
      'bg-orange-500 text-white border-orange-500 font-semibold hover:text-orange-300',
  },
  {
    id: 'confidence',
    value: 'confidence',
    style: 'text-blue-500 border-blue-500 font-semibold hover:text-blue-300',
    selectedStyle:
      'bg-blue-500 text-white border-blue-500 font-semibold hover:text-blue-300',
  },
  {
    id: 'word-range',
    value: 'word range',
    style:
      'text-yellow-500 border-yellow-500 font-semibold hover:text-yellow-300',
    selectedStyle:
      'bg-yellow-500 text-white border-yellow-500 font-semibold hover:text-yellow-300',
  },
];

export interface UserTableObject extends UserData {
  attended?: boolean;
  bookingID?: string;
  following?: boolean;
  bookedAt?: number;
  attendedTime?: number;
  correctionID?: string;
  classCount?: number;
  onRemoveClick?: Function;
  onUserAttendanceUpdate?: Function;
  onCefrChosen?: Function;
  onWorkOnChosen?: Function;
  onImprovedChosen?: Function;
}

export default function UserTable(props: {
  userList: Array<UserTableObject>;
  accessorId?: string;
  sessionId?: string;
  onFollow?: Function;
  classCount?: boolean;
  name?: boolean;
  cefr?: boolean;
  editCefr?: boolean;
  country?: boolean;
  job?: boolean;
  attended?: boolean;
  phone?: boolean;
  allowDeleteBooking?: boolean;
  allowMarkAttendance?: boolean;
  correctionLink?: boolean;
  createdAt?: boolean;
  signedUpAt?: boolean;
  followButton?: boolean;
  evaluate?: boolean;
}) {
  const {
    accessorId,
    sessionId,
    userList,
    name,
    cefr,
    editCefr,
    country,
    job,
    classCount,
    attended,
    phone,
    allowDeleteBooking,
    allowMarkAttendance,
    correctionLink,
    createdAt,
    signedUpAt,
    followButton,
    onFollow,
    evaluate,
  } = props;

  const [users, setUsers] = useState(userList);

  function renderColumnTitles() {
    const titles = [];

    if (followButton) {
      titles.push(
        <th
          scope="col"
          className="py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          Follow
        </th>,
      );
    }

    if (name) {
      titles.push(
        <th
          scope="col"
          className="py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          Name
        </th>,
      );
    }

    if (cefr) {
      titles.push(
        <th
          scope="col"
          className="py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          CEFR
        </th>,
      );
    }

    if (country) {
      titles.push(
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          Country
        </th>,
      );
    }

    if (job) {
      titles.push(
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          Job
        </th>,
      );
    }

    if (attended) {
      titles.push(
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          Attended
        </th>,
      );
    }

    if (classCount) {
      titles.push(
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          Attended #
        </th>,
      );
    }

    if (correctionLink) {
      titles.push(
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          Correction Link
        </th>,
      );
    }

    if (createdAt) {
      titles.push(
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          Booked
        </th>,
      );
    }

    if (signedUpAt) {
      titles.push(
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          Signed Up
        </th>,
      );
    }

    if (evaluate) {
      titles.push(
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          Work on
        </th>,
      );
      titles.push(
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          Improved
        </th>,
      );
    }

    return titles;
  }

  function renderColumnContent(user: UserTableObject) {
    const contents = [];

    if (followButton) {
      contents.push(
        <td className="whitespace-nowrap py-5 md:pr-0 pr-4 text-sm sm:pl-0">
          <FollowButton
            onClick={async () => {
              await onFollow?.(user.following, user.id);
            }}
            followed={user.following ?? false}
          />
        </td>,
      );
    }

    if (name) {
      contents.push(
        <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0">
          <div className="flex items-center">
            <div className="h-11 w-11 flex-shrink-0">
              <img
                key={(user.profilePictureLink ?? 'avatar') + emptyAvatar}
                className="cursor-pointer inline-block h-10 w-10 rounded-full ring-1 ring-gray-200 object-cover object-center bg-white"
                src={user.profilePictureLink ?? emptyAvatar}
                alt={(user.profilePictureLink ?? 'avatar') + emptyAvatar}
                onClick={() => {
                  window.open(
                    `${window.location.origin}/profile/${user?.id}`,
                    '_blank',
                  );
                }}
              />
            </div>
            <div className="ml-4">
              <div className="font-medium text-gray-900">
                {capitalizeEveryFirstLetter(user.name)}
              </div>
              <div className="mt-1 text-gray-500">{user.email}</div>
              <div className="mt-1 text-gray-500">
                {phone ? (
                  <a
                    className={'self-start text-sm'}
                    href={`https://wa.me/${user.phoneNumber?.substring(
                      1,
                      user.phoneNumber?.length,
                    )}`}
                    target={'_blank'}
                    rel={'noopenner noreferrer'}
                  >
                    {user.phoneNumber}
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </td>,
      );
    }

    if (cefr) {
      contents.push(
        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
          <div className="text-gray-900 flex justify-center items-center">
            <span className="mr-2 flex">
              <span>{user.cefrLevel}</span>
              {user.teacherEvaluatedLevelAgg ? (
                <span>{`(${user.teacherEvaluatedLevelAgg})`}</span>
              ) : (
                ''
              )}
            </span>
            <DropDownFancy
              placeHolder="CF"
              title=""
              noDefaultValue={!user.teacherEvaluatedLevel}
              defaultIndex={
                user.teacherEvaluatedLevel
                  ? cefrOptions
                      .map((option) => option.name)
                      .indexOf(user.teacherEvaluatedLevel)
                  : undefined
              }
              options={cefrOptions}
              onOptionSelected={(selected: DropDownFancyOption) => {
                user?.onCefrChosen?.(selected?.name);
              }}
            />
          </div>
          <div className="text-gray-500">{user.cefrElo?.toFixed(2)}</div>
        </td>,
      );
    }

    if (country) {
      contents.push(
        <td className="whitespace-nowrap px-3 py-5 text-sm">
          <div className="text-gray-900">
            {countryCodeToName(user.nationality)}
          </div>
          <div className="text-gray-500">
            {user.livingAbroad === false
              ? countryCodeToName(user.nationality)
              : user.livingAbroad === undefined
              ? undefined
              : countryCodeToName(user.basedCountry)}
          </div>
        </td>,
      );
    }

    if (job) {
      contents.push(
        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
          {capitalizeFirstLetter(user.occupation ?? '')}
        </td>,
      );
    }

    if (attended) {
      contents.push(
        <td>
          <span
            className={cx(
              user.attended
                ? 'bg-green-50 text-green-700'
                : 'bg-red-50 text-red-700',
              'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-green-600/20',
            )}
          >
            {user.attended ? 'Yes' : 'No'}
          </span>
          {user.attendedTime ? (
            <div className="text-gray-500">
              {moment(user.attendedTime).format('MMMM Do, hh:mm A')}
            </div>
          ) : null}
        </td>,
      );
    }

    if (classCount) {
      contents.push(
        <td>
          <span
            className={cx(
              user.classCount
                ? 'bg-green-50 text-green-700'
                : 'bg-blue-immigo text-white',
              'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-green-600/20',
            )}
          >
            {user.classCount ? user.classCount : '🥚 New Student'}
          </span>
        </td>,
      );
    }

    if (correctionLink) {
      contents.push(
        <td>
          <span>
            {user.correctionID && user.bookingID ? (
              <a
                className="cursor-pointer text-blue-immigo"
                href={`https://app.immigo.io/ai?bid=${user.bookingID}`}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            ) : (
              <span></span>
            )}
          </span>
        </td>,
      );
    }

    if (createdAt) {
      contents.push(
        <td>
          <span className="text-gray-400">
            {moment(user.bookedAt).format('MMMM Do, YYYY [at] hh:mm A')}
          </span>
        </td>,
      );
    }

    if (signedUpAt) {
      contents.push(
        <td>
          <span className="text-gray-400">
            {moment(user.createdAt).format('MMMM Do, YYYY [at] hh:mm A')}
          </span>
        </td>,
      );
    }

    if (evaluate) {
      contents.push(
        <td>
          <span className="text-gray-400">
            <PeopleBoxOptionSmall
              onOptionChange={(id: string) => {
                if (sessionId) {
                  let workOn: any = user.workOn ?? [];
                  console.log(workOn);
                  if (!workOn?.includes(id)) workOn.push(id);
                  else workOn = workOn.filter((_id: string) => id !== _id);

                  user.workOn = workOn;
                  setUsers([...userList]);
                  if (accessorId)
                    updateClassSessionUserWorkOn(
                      sessionId,
                      user.id,
                      workOn,
                      accessorId,
                    );
                }
              }}
              optionList={optionList.map((o: any) => {
                o.selected = user.workOn?.includes(o.id);
                return { ...o };
              })}
            />
          </span>
        </td>,
      );
      contents.push(
        <td>
          <span className="text-gray-400">
            <PeopleBoxOptionSmall
              onOptionChange={(id: string) => {
                if (sessionId) {
                  let improved: any = user.improved ?? [];
                  console.log(improved);
                  if (!improved?.includes(id)) improved.push(id);
                  else improved = improved.filter((_id: string) => id !== _id);

                  user.improved = improved;
                  setUsers([...userList]);
                  console.log(user);
                  if (accessorId)
                    updateClassSessionUserImproved(
                      sessionId,
                      user.id,
                      improved,
                      accessorId,
                    );
                }
              }}
              optionList={optionList.map((o: any) => {
                o.selected = user.improved?.includes(o.id);
                return { ...o };
              })}
            />
          </span>
        </td>,
      );
    }

    return contents;
  }

  return (
    <div className="">
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {renderColumnTitles()}
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200 bg-white">
                {users.map((user: UserTableObject) => (
                  <tr key={user.email}>
                    {renderColumnContent(user)}
                    {allowDeleteBooking ? (
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <TrashIcon
                          onClick={() => {
                            user?.onRemoveClick?.();
                          }}
                          className="w-5 h-5 text-red-500 cursor-pointer"
                        />
                      </td>
                    ) : null}
                    {allowMarkAttendance ? (
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {user.attended ? (
                          <UserMinusIcon
                            className="w-5 h-5 text-red-500 cursor-pointer"
                            onClick={async () => {
                              await user?.onUserAttendanceUpdate?.(false);
                            }}
                          />
                        ) : (
                          <UserPlusIcon
                            className="w-5 h-5 text-blue-400 cursor-pointer"
                            onClick={async () => {
                              await user?.onUserAttendanceUpdate?.(true);
                            }}
                          />
                        )}
                      </td>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
