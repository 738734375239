import {
  AcademicCapIcon,
  ChatBubbleLeftEllipsisIcon,
  DocumentTextIcon,
  MegaphoneIcon,
  PencilIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { LearningPathRequirementCard } from '../learingPathRequirementCard/learningPathRequirementCard';
import {
  LearningPathLevelPersonal,
  exampleLearningPathLevelPersonal,
} from '../../../types/learningPath';
import exampleIcon from '../../../images/icons/negotiation.svg';
import { SpreadBookingModal } from '../../booking/modals/spreadBookingModal';
import { useEffect, useState } from 'react';
import { ClassType } from '../../../types/class';
import { UserData } from '../../../types/user';
import { useSelector } from 'react-redux';
import {
  getUserBookings,
  getUserFutureBookings,
} from '../../../firebase/subscription/bookings/bookings';
import { BookingData } from '../../../firebase/subscription/subscription';
import { trackLearningPathData } from '../../../features/LearningPath/analytics';
import { LearningPathCardVertical } from '../learningPathCardVertical/learningPathCardVertical';
import cx from 'classix';

const classTypeToColor: any = {
  idiom: 'green',
  vocab: 'yellow',
  pronunciation: 'orange',
  'free-talking': 'blue',
  grammar: 'red',
};

const classTypeToIcon: any = {
  idiom: ChatBubbleLeftEllipsisIcon,
  vocab: AcademicCapIcon,
  pronunciation: MegaphoneIcon,
  'free-talking': UserGroupIcon,
  grammar: DocumentTextIcon,
};

export const LearningPathLevel = (props: {
  learningPathLevel: LearningPathLevelPersonal;
  minimized?: boolean;
}) => {
  const { learningPathLevel, minimized } = props;

  const [completePercentage, setCompletePercentage] = useState(0);

  console.log(learningPathLevel);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );
  const unlimited =
    userData?.product?.metadata?.credits === 'inf' && !userData?.servicePaused; // This needs replacing
  const learningPathTitle = 'Mastering Professional English Communication';

  const [classSuggestionType, setClassSuggestedType] = useState<
    ClassType | undefined
  >();
  const [openClassSuggestionModal, setOpenClassSuggestionModal] =
    useState(false);

  const [bookedClassSessionIds, setBookedClassSessionIds] = useState<
    Array<string>
  >([]);

  const [bookings, setUserBookings] = useState<Array<BookingData>>([]);

  async function updateUserBookings(id: string) {
    const bookings = await getUserFutureBookings(id);
    setUserBookings(bookings);
    setBookedClassSessionIds(
      bookings?.map((b: BookingData) => b.classSessionID ?? ''),
    );
  }

  useEffect(() => {
    if (userData?.id) updateUserBookings(userData.id);
  }, [userData]);

  useEffect(() => {
    if (learningPathLevel) {
      let totalNeed = 0;
      let totalComplete = 0;
      learningPathLevel.requirements?.map((req) => {
        totalNeed += req.needed;
        totalComplete += req.completed ?? 0;
      });
      setCompletePercentage(Math.max((totalComplete / totalNeed) * 100));
    }
  }, [learningPathLevel]);

  const currentLevelString = `${learningPathLevel?.id?.split('-')?.[0]} Level ${
    learningPathLevel?.id?.split('-')?.[1]
  }`;
  const nextLevelString = learningPathLevel?.nextLevelId
    ? `${learningPathLevel.nextLevelId?.split('-')?.[0]} Level ${
        learningPathLevel.nextLevelId?.split('-')?.[1]
      }`
    : '-';

  return (
    <div>
      {classSuggestionType && userData?.availableClasses !== undefined ? (
        <SpreadBookingModal
          onBooking={() => {
            setOpenClassSuggestionModal(false);
            trackLearningPathData(
              'Booked class in learning path level suggestion modal',
            );
          }}
          bookings={bookings}
          open={openClassSuggestionModal}
          onClose={() => {
            setOpenClassSuggestionModal(false);
          }}
          availableClassesNumber={userData?.availableClasses}
          bookedClassSessionIds={bookedClassSessionIds}
          unlimited={unlimited}
          onOpen={() => {}}
          userID={userData.id}
          classTypeFilter={classSuggestionType}
        />
      ) : null}
      <div className=" mb-2 bg-white rounded-md p-4">
        <span className="flex items-center">
          <img
            className="bg-opacity-50 w-12 h-12 mr-4 fill-current text-red-warning"
            src={exampleIcon}
            alt="share-screen-icon"
          />
          <span className="text-xl font-semibold">{learningPathTitle}</span>
        </span>
        <div className="overflow-hidden rounded-full bg-gray-200 mt-4">
          <div
            className="h-4 rounded-full bg-blue-immigo"
            style={{
              width: `${completePercentage}%`,
            }}
          />
        </div>
        {currentLevelString && completePercentage ? (
          <div className="mt-1 flex justify-between">
            <span className="text-blue-immigo text-sm">{`${currentLevelString} ${completePercentage}% complete`}</span>
            <div className="text-sm text-gray-500">{`Next: ${nextLevelString}`}</div>
          </div>
        ) : null}
      </div>
      <div
        className={cx(
          minimized
            ? 'flex w-full overflow-y-scroll py-2 px-4 bg-white rounded-md'
            : '',
        )}
      >
        {learningPathLevel?.requirements.map((requirement) => {
          if (!minimized)
            return (
              <div className="mb-2">
                <LearningPathRequirementCard
                  key={requirement.name}
                  colorTheme={classTypeToColor[requirement.type]}
                  Icon={classTypeToIcon[requirement.type]}
                  title={requirement.name}
                  description={requirement.description}
                  completed={requirement.completed ?? 0}
                  needed={requirement.needed}
                  onSuggestionClick={() => {
                    setOpenClassSuggestionModal(true);
                    setClassSuggestedType(requirement.type);
                    trackLearningPathData(
                      'Clicked suggested class in learning path level',
                    );
                  }}
                />
              </div>
            );
          else {
            return (
              <div className="mr-2 flex">
                <LearningPathCardVertical
                  key={requirement.name}
                  colorTheme={classTypeToColor[requirement.type]}
                  title={requirement.name}
                  description={requirement.description}
                  completed={requirement.completed ?? 0}
                  needed={requirement.needed}
                  Icon={classTypeToIcon[requirement.type]}
                  onSuggestionClick={() => {
                    setOpenClassSuggestionModal(true);
                    setClassSuggestedType(requirement.type);
                    trackLearningPathData(
                      'Clicked suggested class in learning path level',
                    );
                  }}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
