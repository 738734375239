import { useEffect, useState } from 'react';
import VerticalMessageModal from '../verticalMessageModal/verticalMessageModal';
import { returnUserByUID } from '../../../firebase/configuration';
import { UserData } from '../../../types/user';
import { getFirstNameFromFullName } from '../../../util/helper';
import { capitalizeFirstLetter } from '../../../util/standardization';
import { Avatar } from '../../avatar/avatar';

export const TeacherInputModal = (props: {
  isOpen: boolean;
  onCloseModal: Function;
  message: string;
  teacherId: string;
}) => {
  const { isOpen, onCloseModal, message, teacherId } = props;

  const [teacher, setProfile] = useState<undefined | UserData>();

  async function onTeacherIdLoad() {
    const user = (await returnUserByUID(teacherId)) as UserData;
    setProfile(user);
  }

  useEffect(() => {
    onTeacherIdLoad();
  }, [teacherId]);

  return (
    <>
      {teacher ? (
        <VerticalMessageModal
          isOpen={isOpen}
          onCloseModal={onCloseModal}
          mainMessage={`You got a message from Coach ${capitalizeFirstLetter(
            getFirstNameFromFullName(teacher.name),
          )}!`}
          subMessage=""
          buttonText="Continue"
          onButtonClick={() => {}}
          theme="success"
          icon="pencil"
        >
          <div className="flex justify-center items-center">
            <Avatar
              photoUrl={teacher.profilePictureLink}
              className="mr-2 h-10 w-10"
            />
            <div className="text-gray-500">"{message}"</div>
          </div>
        </VerticalMessageModal>
      ) : null}
    </>
  );
};
