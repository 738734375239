import cx from 'classix';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useForm } from '../../components/forms/hooks';
import { LoadingOverlay } from '../../components/loading';

import {
  getUserByUID,
  updateStudentInformation,
} from '../../firebase/configuration';

import {
  AdditionalInfoFormData,
  kindsOfWork,
  partnerExperienceOptions,
  relationshipStatuses,
  studyFocuses,
  typesOfStudent,
  usesEnglishOptions,
  validationSchema,
  cefrLevels,
} from './data';
import { Input } from '../../components/forms/input';
import { Select } from '../../components/forms/select';
import {
  arrayAsSelectOptions,
  countryCodeToName,
  countryNameToCode,
  nationalitiesOptions,
  yesNoOptions,
} from '../../util/forms';
import { Radio } from '../../components/forms/radio';
import { CustomField } from '../../components/forms/custom-field';
import PhoneInput from 'react-phone-number-input';

import { onAuthStateChanged } from 'firebase/auth';
import { initializeFirebase } from '../../firebase/configValues';
import { livingAbroadDurationOptions } from '../CourseRegistration/questionform/data';
import { getCountryCallingCode } from 'react-phone-number-input';
import TextInput from '../../components/forms/textInput';

const { auth } = initializeFirebase();

interface AdditionalInfoProps {
  userData: Record<string, any>;
  onSetUserData?: (newUserData: Record<string, any>) => void;
}

const removeUndefinedProperties = (
  obj: Record<string, unknown | undefined>,
): Record<string, unknown> => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return obj;
};

const ProfileSetupComponent: FC<AdditionalInfoProps> = (props) => {
  const { userData, onSetUserData } = props;

  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const formik = useForm<AdditionalInfoFormData>({
    initialValues: {
      ...userData,
      nationality: countryCodeToName(userData?.nationality),
      basedCountry: countryCodeToName(userData?.basedCountry),
      occupation: userData?.exactOcc || userData?.occupation,
      cefrLevel:
        typeof userData?.cefrLevel === 'number'
          ? cefrLevels[userData.cefrLevel]
          : userData?.cefrLevel || '',
      relationshipStatus:
        typeof userData?.relationshipStatus === 'number'
          ? relationshipStatuses[userData.relationshipStatus]
          : userData?.relationshipStatus || '',
      kindOfWork: kindsOfWork.find((kind) =>
        kind
          .toLowerCase()
          .includes(userData?.kindOfWork?.toString().toLowerCase()),
      ),
    },
    validationSchema,
    onSubmit: async (values) => {
      if (userData && userData.id) {
        if (values['nationality'])
          values['nationality'] = countryNameToCode(values['nationality']);
        if (values['basedCountry']) {
          values['basedCountry'] = countryNameToCode(values['basedCountry']);
        }
        await updateStudentInformation(
          userData.id,
          removeUndefinedProperties({
            ...values,
            haveSpeakingPartner: values.currentlyHasSpeakingPartner,
            exactOcc: values.occupation,
          }),
        );
        window.location.href = '/home';
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (userData && onSetUserData) {
      setSubmitting(true);
      getUserByUID(userData.id, (data: any) => {
        setSubmitting(true);
        onSetUserData(data);
        setSubmitting(false);
      });
    }

    onAuthStateChanged(auth, (user) => {
      if (!user) history.push('/login');
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex w-full justify-center">
      <div className="">
        <div className="flex flex-col pb-20 max-w-prose mx-auto justify-center py-10 px-4 lg:px-0">
          <LoadingOverlay enabled={submitting || formik.isSubmitting} />
          <div className={'flex flex-col gap-4'}>
            <h2 className="text-3xl md:text-4xl font-semibold text-blue-immigo">
              Before we get started…
            </h2>
            <p>
              So excited to have you! We just need a few more details from you
              to for us to better customize your learning experience.
            </p>
            <form className={'flex flex-col gap-8 form'}>
              <Input
                label={'Age'}
                description={'How old are you?'}
                min={0}
                type={'number'}
                {...formik.getFieldProps('age')}
              />
              <Select
                label={'What is your CEFR level?'}
                {...formik.getSelectProps('cefrLevel')}
                options={arrayAsSelectOptions(cefrLevels)}
              />
              <Select
                label={'Where are you from? (What is your nationality?)'}
                {...formik.getSelectProps('nationality', undefined, true)}
                options={nationalitiesOptions}
              />
              <Radio
                label={'Are you living abroad?'}
                {...formik.getRadioProps(
                  'livingAbroad',
                  (value) => (value ? 'yes' : 'no'),
                  (newValue) => {
                    formik.setFieldValue('livingAbroad', newValue === 'yes');
                  },
                )}
                options={yesNoOptions}
              />
              {formik.values.livingAbroad === true && (
                <>
                  <Select
                    label={'Where are you currently living?'}
                    {...formik.getSelectProps('basedCountry', undefined, true)}
                    options={nationalitiesOptions}
                  />
                  <Select
                    label={'How long have you lived in this foreign country?'}
                    {...formik.getSelectProps('howLongLivedAbroad')}
                    options={arrayAsSelectOptions(livingAbroadDurationOptions)}
                  />
                </>
              )}
              <Select
                label={'What is your relationship status?'}
                {...formik.getSelectProps('relationshipStatus')}
                options={arrayAsSelectOptions(relationshipStatuses)}
              />
              <Radio
                label={'Do you have kids?'}
                {...formik.getRadioProps(
                  'haveKids',
                  (value) => (value ? 'yes' : 'no'),
                  (newValue) => {
                    formik.setFieldValue('haveKids', newValue === 'yes');
                  },
                )}
                options={yesNoOptions}
              />
              <Select
                label={'What kind of work do you do?'}
                {...formik.getSelectProps('kindOfWork')}
                options={arrayAsSelectOptions(kindsOfWork)}
              />
              {formik.values.kindOfWork === kindsOfWork[0] && (
                <>
                  <Select
                    label={'What type of student are you?'}
                    {...formik.getSelectProps('typeOfStudent')}
                    options={arrayAsSelectOptions(typesOfStudent)}
                  />
                  <Select
                    label={'What are you studying?'}
                    description={'Your main focus'}
                    {...formik.getSelectProps('studyFocus')}
                    options={arrayAsSelectOptions(studyFocuses)}
                  />
                </>
              )}
              <Input
                label={'What exactly is your occupation?'}
                {...formik.getFieldProps('occupation')}
              />
              <Select
                label={'Do you use English at work?'}
                {...formik.getSelectProps('usesEnglishAtWork')}
                options={arrayAsSelectOptions(usesEnglishOptions)}
              />
              <Radio
                label={'Do you currently have a tutor?'}
                description={'e.g. 1:1 class or a group class'}
                {...formik.getRadioProps(
                  'currentlyHasTutor',
                  (value) => (value ? 'yes' : 'no'),
                  (newValue) => {
                    formik.setFieldValue(
                      'currentlyHasTutor',
                      newValue === 'yes',
                    );
                  },
                )}
                options={yesNoOptions}
              />
              <Radio
                label={'Have you tried finding a speaking partner?'}
                {...formik.getRadioProps(
                  'triedSpeakingPartner',
                  (value) => (value ? 'yes' : 'no'),
                  (newValue) => {
                    formik.setFieldValue(
                      'triedSpeakingPartner',
                      newValue === 'yes',
                    );
                  },
                )}
                options={yesNoOptions}
              />
              <Radio
                label={'Do you currently have a speaking partner?'}
                {...formik.getRadioProps(
                  'currentlyHasSpeakingPartner',
                  (value) => (value ? 'yes' : 'no'),
                  (newValue) => {
                    formik.setFieldValue(
                      'currentlyHasSpeakingPartner',
                      newValue === 'yes',
                    );
                  },
                )}
                options={yesNoOptions}
              />
              {formik.values.currentlyHasSpeakingPartner && (
                <Select
                  label={
                    'How would you rate your experience with your speaking partner?'
                  }
                  {...formik.getSelectProps('speakingPartnerExperience')}
                  options={arrayAsSelectOptions(partnerExperienceOptions)}
                />
              )}
              <Input
                asTextArea
                label={'Why did you join us?'}
                {...formik.getFieldProps('whyJoinedCourse')}
              />
              <CustomField
                label={
                  'Whatsapp (or WeChat) Phone number (with country code with +)'
                }
                description={
                  'Your phone number will only be used internally to send you class related details.'
                }
                {...formik.getFieldProps('phoneNumber')}
              >
                <TextInput
                  value={formik.values.phoneNumber}
                  onChange={(value: string) => {
                    if (value?.[0] !== '+') value = '+' + value;
                    formik.setFieldValue('phoneNumber', value);
                  }}
                />
              </CustomField>
              <Radio
                label={
                  'Do you wish to be contacted by other members of Immigo community if they want to practice English with you?'
                }
                {...formik.getRadioProps(
                  'wishToBeContacted',
                  (value) => (value ? 'yes' : 'no'),
                  (newValue) => {
                    formik.setFieldValue(
                      'wishToBeContacted',
                      newValue === 'yes',
                    );
                  },
                )}
                options={yesNoOptions}
              />
              <Input
                asTextArea
                label={
                  '💙 What would you like your Immigo community members to know about you?'
                }
                {...formik.getFieldProps('infoToKnow')}
              />
              <Radio
                label={'Disclaimer:'}
                description={
                  'Immigo is only for serious learners committed to practicing. Your participation impacts other members experience. Do you agree to be committed, focused, and responsible to others?'
                }
                {...formik.getRadioProps(
                  'seriousLearner',
                  (value) => (value ? 'yes' : 'no'),
                  (newValue) => {
                    formik.setFieldValue('seriousLearner', newValue === 'yes');
                  },
                )}
                options={yesNoOptions}
              />

              <div className={'flex flex-col gap-4'}>
                <button
                  type={'button'}
                  disabled={formik.isSubmitting || formik.isValidating}
                  className={cx(
                    'w-full bg-blue-immigo px-4 py-3',
                    'text-center text-white rounded font-semibold',
                    'hover:shadow hover:-translate-y-0.5 transform transition-all ease-in-out',
                    'disabled:opacity-80 disabled:cursor-not-allowed disabled:transform-none',
                  )}
                  onClick={() => {
                    formik.submitForm();
                  }}
                >
                  Submit
                </button>
                {Boolean(Object.keys(formik.errors).length) && (
                  <p
                    className={cx(
                      'text-red-warning border border-red-warning border-opacity-30 border-dashed',
                      'px-2 py-1 rounded-sm mt-1 self-start font-semibold',
                    )}
                  >
                    <span role={'img'} aria-label={'emergency light emoji'}>
                      🚨
                    </span>{' '}
                    Please scroll up to make sure all the information is filled
                    out correctly!
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  onSetUserData: (userData: any) =>
    dispatch({ type: 'USER_DATA_SET', userData }),
});

const mapStateToProps = (state: any) => ({
  userData: state.sessionState.userData,
});

export const ProfileSetup = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileSetupComponent);
