import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/user'
    : 'http://localhost:8080/user'; // Dev env

export async function getUserNextHook(uid: string, type: string) {
  const data = await axios.get(`${endPoint}/getUserNextHook/${uid}/${type}`);
  return data?.data?.data;
}

export async function createTeacherMessageForUser(
  name: string,
  workOn: Array<string>,
  improved: Array<string>,
  raterId: string,
  sessionIds: Array<string>,
  uid: string,
) {
  const data = await axios.post(`${endPoint}/teacherMessage`, {
    name,
    workOn,
    improved,
    raterId,
    sessionIds,
    uid,
  });
  return data?.data?.data;
}
