import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Visited pricing page' //
  | 'Chose learning plan at pricing page' //
  | 'Switched to monthly button on learning plan' //
  | 'Switched to yearly button on learning plan' //
  | 'Unmarked want recurring schedule on pricing page' //
  | 'Marked want recurring schedule on pricing page' //
  | 'Selected a class in pricing page' //
  | 'Deselected a class in pricing page' //
  | 'Clicked choose a different learning plan' //
  | 'Submitted booking on pricing page' //
  | 'Clicked change class booking' //
  | 'Paid for the class subscription' //
  | 'Clicked continue on pricing page paid confirmation' //
  | 'Closed pricing page paid confirmation modal' //
  | 'Clicked book a class at booking calendar' //
  | 'Clicked trash icon on booking calendar' //
  | 'Clicked cancel on booking cancel confirmation modal' //
  | 'Clicked not today on booking cancelation modal' //
  | 'Closed booking cancelation modal' //
  | 'Clicked a date on upcoming classes calendar' //
  | 'Selected a class on booking class modal' //
  | 'Closed booking class modal' //
  | 'Clicked submit booking on booking class modal' //
  | 'Booked a class on booking class modal' // Need to be more robust
  | 'Clicked class link in the homepage booking modal'
  | 'Clicked preview slide on booking calendar modal'
  | 'Clicked view full calendar on booking page'
  | 'Clicked back to recommended classes on booking page'
  | 'Selected a journey on journey page'
  | 'Clicked expand booking calendar'
  | 'Booked classes on expanded booking calendar';

type UserData = {
  userID?: string;
  subOption?: number;
  classID?: string;
  selectedDate?: string;
  bookingTitle?: string;
  bookingStartMili?: number;
  cefrLevel?: string;
  motivation?: string;
  nationality?: string;
  basedCountry?: string;
  trial?: boolean;
  bookFor?: string;
  cityTag?: string;
};

export const trackBookingAnalytics = (event: Events, data: UserData = {}) => {
  mixpanel.track(event, data);
};
