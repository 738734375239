import { useEffect, useRef, useState } from 'react';
import { TranscriberCorrector } from '../../components/ai/transcriberCorrector';
import { ChatBox, ChatBoxText } from '../../components/chat/chatBox';
import moment from 'moment-timezone';
import { Correction } from '../../types/feedback';
import { getCorrectionAI } from '../../api';
import { getCorrectionPair } from '../../util/feedback';
import { MicrophoneIcon } from '@heroicons/react/24/solid';
import { PlayIcon } from '@heroicons/react/20/solid';
import { trackAIDemoPage } from '../../features/AIDemoPage/analytics';

const pressButton = (
  <div className="flex md:flex-row flex-col justify-center items-center">
    <span className="md:text-xl text-md text-white font-semibold md:mr-4 md:mb-0 mb-2">
      Press the{' '}
    </span>
    <div className="rounded-full border-red-500 border-4 md:w-12 md:h-12 flex items-center justify-center text-red-500">
      <MicrophoneIcon className="w-8 h-8 p-2" />
    </div>
    <span className="md:text-xl text-md text-white font-semibold md:ml-4 md:mb-0 mb-2">
      button below
    </span>
  </div>
);

const pressButtonPlay = (
  <div className="flex md:flex-row flex-col justify-center items-center">
    <span className="md:text-xl text-md text-white font-semibold md:mr-4 md:mb-0 mb-2">
      Press the{' '}
    </span>
    <div className="rounded-full border-red-500 border-4 md:w-12 md:h-12 flex items-center justify-center text-red-500 md:mb-0 mb-2">
      <PlayIcon className="w-8 h-8 p-2" />
    </div>
    <span className="md:text-xl text-md text-white font-semibold md:ml-4">
      button below to try Immigo
    </span>
  </div>
);

const scriptOverlay = (
  <div className="flex md:flex-row flex-col justify-center items-center">
    <div className="text-xl text-red-500 font-semibold">Read outloud:</div>
    <span className="text-xl text-white font-semibold ml-4">
      I love playing the basketball
    </span>
  </div>
);

const one = (
  <div className="flex justify-center items-center">
    <span className="text-xl text-white font-semibold">1</span>
  </div>
);

const two = (
  <div className="flex justify-center items-center">
    <span className="text-xl text-white font-semibold">2</span>
  </div>
);

const three = (
  <div className="flex justify-center items-center">
    <span className="text-xl text-white font-semibold">3</span>
  </div>
);

const readThis = <div></div>;

export const AIDemoPage = (props: {}) => {
  const [partialTranscript, setPartialTranscript] = useState<
    undefined | string
  >();

  const [transcript, setTranscript] = useState<Array<string>>([]);
  const [correction, setCorrection] = useState<Correction | undefined>();
  const [chatText, setChatTextContent] = useState<Array<ChatBoxText>>([]);
  const [pauseTranscriber, setPauseTranscriber] = useState(false);
  const [play, setPlay] = useState(false);
  const [readyToSpeak, setReadyToSpeak] = useState(false);
  const [countDownPage, setCountDownPage] = useState<any>();
  const [scriptReady, setScriptReady] = useState(false);

  const video1Ref: any = useRef();
  const video2Ref: any = useRef();

  async function scenarioPlayHandler() {
    setTimeout(() => {
      video1Ref.current.play();
      video2Ref.current.play();

      trackAIDemoPage('Demo first message sent');
      setChatTextContent([
        {
          content: [
            `Even though I'm not really into sports, I really enjoy doing Zumba because of the music, and it kind of keeps me more motivated.`,
          ],
          time: moment().format('HH:mm a'),
          name: 'Sarah',
          type: 'text',
          you: false,
          img: 'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FDdvKH2MqSQZttaFFXFQRT4tu0Iw2_profile_picture?alt=media&token=a9690936-353e-4bf0-bd27-b4d1777d1888',
        },
      ]);
    }, 1000);

    setTimeout(() => {
      trackAIDemoPage('Demo second message sent');
      setChatTextContent([
        {
          content: [
            `Even though I'm not really into sports, I really enjoy doing Zumba because of the music, and it kind of keeps me more motivated.`,
          ],
          time: moment().format('HH:mm a'),
          name: 'Sarah',
          type: 'text',
          you: false,
          img: 'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FDdvKH2MqSQZttaFFXFQRT4tu0Iw2_profile_picture?alt=media&token=a9690936-353e-4bf0-bd27-b4d1777d1888',
        },
        {
          content: [
            `Yeah, for me I really love fishing, I think it really helps me relax. What about you, what is your hobby?`,
          ],
          time: moment().format('HH:mm a'),
          name: 'Ryan',
          type: 'text',
          you: false,
          img: 'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/JIN07626.jpg?alt=media&token=b15f5157-3373-474f-bec6-1217e69da319',
        },
      ]);
    }, 14000);

    setTimeout(() => {
      setReadyToSpeak(true);
      video1Ref.current.pause();
      video2Ref.current.pause();
    }, 23000);
  }

  async function onTranscriptLoaded() {
    if (transcript?.length > 0) {
      setPauseTranscriber(true);
      setScriptReady(false);
      console.log(
        transcript
          .map((t) => {
            return t.split(':')[1];
          })
          .join(' '),
      );
      const correction_: Correction | undefined = await getCorrectionPair(
        transcript
          .map((t) => {
            return t.split(':')[1];
          })
          .join(' '),
        true,
      );
      setCorrection(correction_);
      if (correction_) {
        setChatTextContent([
          {
            content: [
              `Even though I'm not really into sports, I really enjoy doing Zumba because of the music, and it kind of keeps me more motivated.`,
            ],
            time: moment().format('HH:mm a'),
            name: 'Sarah',
            type: 'text',
            you: false,
            img: 'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FDdvKH2MqSQZttaFFXFQRT4tu0Iw2_profile_picture?alt=media&token=a9690936-353e-4bf0-bd27-b4d1777d1888',
          },
          {
            content: [
              `Yeah, for me I really love fishing, I think it really helps me relax. What about you, what is your hobby?`,
            ],
            time: moment().format('HH:mm a'),
            name: 'Ryan',
            type: 'text',
            you: false,
            img: 'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/JIN07626.jpg?alt=media&token=b15f5157-3373-474f-bec6-1217e69da319',
          },
          {
            content: transcript.map((t) => {
              trackAIDemoPage('Demo transcription sent', {
                text: t.split(':')[1],
              });
              return t.split(':')[1];
            }),
            time: moment().format('HH:mm a'),
            name: 'You',
            type: 'text',
            you: true,
          },
          {
            content: [
              `${correction_?.original}|${correction_?.fixed}|${correction_?.explanation}`,
            ],
            time: moment().format('HH:mm a'),
            name: 'Immigo AI',
            type: 'correction',
            you: false,
            img: 'https://media.istockphoto.com/id/1927293012/vector/cute-yellow-and-blue-robot.jpg?s=612x612&w=0&k=20&c=yz-GVpHq_IRpLfFnDRhXs0GpNiJMeA0oR8eIY2KI7yY=',
          },
        ]);
        trackAIDemoPage('Demo correction made', {
          explanation: correction_.explanation,
          fixed: correction_.fixed,
          original: correction_.original,
        });
      }
    }
  }

  function overlayRenderer() {
    if (!play) {
      return pressButtonPlay;
    } else if (countDownPage) {
      return countDownPage;
    } else if (readyToSpeak) {
      return pressButton;
    } else if (scriptReady) {
      return scriptOverlay;
    } else {
      return undefined;
    }
  }

  function resetScenario() {
    video1Ref.currentTime = 0;
    video2Ref.currentTime = 0;
    setChatTextContent([]);
    setCorrection(undefined);
    setTranscript([]);
    setScriptReady(false);
    setCountDownPage(undefined);
  }

  useEffect(() => {
    if (transcript.length > 0 && chatText.length < 3) {
      onTranscriptLoaded();
    }
    console.log(transcript);
  }, [transcript]);

  useEffect(() => {
    if (play) scenarioPlayHandler();
  }, [play]);

  useEffect(() => {
    document.body.classList.toggle('dark'); // make dark mode
  }, []);

  return (
    <div className="w-full h-full dark:bg-slate-950">
      <div className="md:min-w-[1000px] md:p-4 dark:bg-slate-950">
        <div className="flex md:flex-row flex-cols grid md:grid-cols-2 grid-cols-1 md:h-[500px] md:h-[1400px] md:grid-rows-1 grid-rows-7">
          <div className="grid md:grid-rows-4 grid-rows-5 md:order-first order-last row-span-5">
            <div className="row-span-4 relative ">
              {overlayRenderer() ? (
                <div className="absolute bg-gray-800 bg-opacity-50 w-full h-full flex justify-center">
                  {overlayRenderer()}
                </div>
              ) : null}
              <ChatBox
                texts={[
                  ...chatText,
                  {
                    content: partialTranscript ? [partialTranscript] : [],
                    type: 'partial',
                    you: true,
                  },
                ]}
              />
            </div>
            <div className="row-span-1 relative">
              {!play ? (
                <div className="absolute flex justify-center w-full bg-white dark:bg-slate-950 h-full">
                  <span
                    className="rounded-full border-red-500 border-4 md:w-24 md:h-24 w-12 h-12 flex items-center justify-center cursor-pointer text-red-500 hover:border-red-300 hover:text-red-300 mt-4"
                    onClick={() => {
                      resetScenario();
                      setPlay(true);
                      trackAIDemoPage('Played demo scenario');
                    }}
                  >
                    <PlayIcon className='md:w-14 md:h-14 w-6 h-6 p-2"' />
                  </span>
                </div>
              ) : null}
              <TranscriberCorrector
                onPartialTranscript={(t: string) => {
                  setPartialTranscript(t);
                }}
                onTextResult={(t: Array<string>) => {
                  console.log(t);
                  setTranscript(t);
                }}
                pauseTranscriber={pauseTranscriber}
                disableButton={!readyToSpeak}
                onTranscribe={() => {
                  trackAIDemoPage('Demo clicked mic button');
                  setTimeout(() => {
                    setCountDownPage(three);
                  }, 1000);
                  setTimeout(() => {
                    setCountDownPage(two);
                  }, 2000);
                  setTimeout(() => {
                    setCountDownPage(one);
                  }, 3000);
                  setTimeout(() => {
                    setCountDownPage(undefined);
                    setReadyToSpeak(false);
                    setScriptReady(true);
                  }, 4000);
                }}
              />
            </div>
          </div>
          <div className="grid md:grid-rows-2 grid-rows-1 md:grid-cols-1 grid-cols-2 md:row-span-1 row-span-2">
            <video
              ref={video1Ref}
              webkit-playsinline="true"
              playsInline
              muted
              poster="https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/website_photos%2Fimmersion%2Fdemo-sarah-thumbnail.png?alt=media&token=530af876-680e-485d-b568-74c0436294ee"
              className="object-cover max-w-[500px] w-full h-full row-span-1 md:max-h-[300px] max-h-[150px] mt-auto"
              src="https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/website_photos%2Fimmersion%2Fdemo-sarah.mp4?alt=media&token=29ddbf62-5bdd-4673-80a5-c70defc0464d"
            />
            <video
              ref={video2Ref}
              webkit-playsinline="true"
              playsInline
              muted
              poster="https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/website_photos%2Fimmersion%2Fdemo-ryan-thumbnail-zoom.png?alt=media&token=de57797f-7815-4216-a5b5-275ed743b756"
              className="object-cover max-w-[500px] w-full h-full row-span-1 md:max-h-[300px] max-h-[150px] mt-auto"
              src="https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/website_photos%2Fimmersion%2Fdemo-ryan-zoom.mp4?alt=media&token=3f92c540-3306-4050-99de-a2d30a2475fc"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
