import cx from 'classix';

interface PeopleBoxOptionObject {
  id: string;
  value: string;
  style?: string;
  selectedStyle?: string;
  selected?: boolean;
}

export const PeopleBoxOptionSmall = (props: {
  onOptionChange: Function;
  optionList: Array<PeopleBoxOptionObject>;
  multiple?: boolean;
}) => {
  const { onOptionChange, optionList, multiple } = props;

  return (
    <div>
      <div className="ml-3  grid grid-cols-2 min-w-[200px] gap-y-1">
        {optionList.map((option: PeopleBoxOptionObject) => {
          return (
            <span
              key={option.value}
              className={cx(
                'border-2 rounded-md p-1 text-xs',
                'cursor-pointer mx-1 text-center col-span-1',
                option.selected ? option.selectedStyle : option.style,
              )}
              onClick={() => {
                console.log(option);
                onOptionChange?.(option?.id);
              }}
            >
              {option.value}
            </span>
          );
        })}
      </div>
    </div>
  );
};
